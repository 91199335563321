import React, {useEffect, useState} from 'react'
import {FormattedNumber} from 'react-intl'
import {observer} from 'mobx-react-lite'
import {Center, useDisclosure, Text, Box, Stack, Flex, Button} from '../../vanilla'
import {Modal} from '../modal'
import {BasketIcon} from '../icons'
import {
  useBasketStore,
  useGlobalStore,
  useOrderStore,
} from '../../store/hooks/useStore'
import {dialogActionButton} from './styles.css'
import useNavigation from '../../hooks/use-navigation'

export const MergeBasketsModal = observer(() => {
  const navigate = useNavigation()
  const modal = useDisclosure()

  const globalStore = useGlobalStore()
  const basketStore = useBasketStore()

  const [isTransferring, setIsTransferring] = useState(false)

  useEffect(() => {
    if(!basketStore.initMergingUI || basketStore.basketMergeType === '') { return }
    // Initiate merge for edit, reorder or guest to customer basket
    modal.onOpen()
  }, [basketStore.initMergingUI])

  const startMerge = async (useStored: boolean, isSubmit: boolean) => {
    setIsTransferring(true)
    const result = await basketStore.initMergeBasket(useStored, isSubmit)

    if (result?.redirect) {
      navigate(result.redirect)
    }

    setIsTransferring(false)
    modal.onClose()
  }

  return (
    <Modal size="xl" closeIcon={false} {...modal}>
      <Box width="full" bg="white" paddingX="28px" paddingY="16px">
        <Text variant="heading1">Before You Change Your Order</Text>
      </Box>
      <Center
        gap="20px"
        flexDirection="column"
        bg="gray50"
        paddingBottom={['20px', '48px']}
        paddingTop={['32px', '40px']}
      >
        <Text variant="unstyled" weight="normal" fontSize={['md', 'lg']} align="center">
          You have items in your basket, <br /> what would you like to do with them?
        </Text>
        <Stack align="center" bg="white" spacing="24px" paddingY="40px" style={{width: 245}}>
          <Center bg="accent3" borderRadius="full" width="92px" height="92px">
            <BasketIcon color="white" boxSize="40px" />
          </Center>
          <Stack spacing="12px">
            <Text variant="text3">Current basket</Text>
            <Box>
              <Text align="center" variant="unstyled" fontSize="xl" weight="bold">
                <FormattedNumber
                  style="currency"
                  currency={globalStore.currency}
                  value={basketStore?.mergeBasketTotal || basketStore?.basket?.productSubTotal}
                />
              </Text>
              <Text variant="text1" align="center" lineHeight="short" color="textMuted">
                {basketStore?.mergeBasketCount || basketStore?.count || 0} items
              </Text>
            </Box>
          </Stack>
        </Stack>
      </Center>
      <Flex
        bg="white"
        padding="12px"
        width="full"
        direction={['column', 'row']}
        justify={['flex-start', 'center']}
        gap={['12px', '20px']}
      >
        <Button
          size="sm"
          disabled={isTransferring}
          variant="primary"
          extraClassName={dialogActionButton}
          onClick={() => {
            startMerge(false, true)
          }}
        >
          Add These to My Order
        </Button>
        <Button
          disabled={isTransferring}
          size="sm"
          variant="secondary"
          extraClassName={dialogActionButton}
          onClick={() => {
            startMerge(true, false)
          }}
        >
          {"I Don't Want These Items"}
        </Button>
      </Flex>
    </Modal>
  )
})
