import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IntlProvider, MessageFormatElement } from 'react-intl'
import { flowResult } from 'mobx'
import { TObservablePage } from '../../types/commerce'
import { Box } from '../../vanilla'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { SimpleHeader } from '../header/simple-header'
import Header from '../header'
import { SimplyFooter } from '../footer/simply-footer'
import Footer from '../footer'
import { useContentStore, useCustomerStore } from '../../store/hooks/useStore'
import { Slot } from '../cms/Slot'
import { SlotContentItem } from '../../types/cms'
import { MergeBasketsModal } from '../merge-baskets-modal'
import { EditOrderExpiredModal } from '../edit-order-expired-modal'
import { useAutoLogout } from '../../hooks/use-auto-logout'
import { AlgoliaAnalyticsContext, AlgoliaAnalyticsSetup } from '../../analytics/algolia'
import * as Sentry from '@sentry/react'
import { useSentryAnalytics } from '../../analytics/sentry'
import 'react-multi-carousel/lib/styles.css'
import '../../theme.css'
import './styles.css.ts'
import Chat from '../chat'
import { useUserDataTracking } from '../../analytics/user-data-tracking'
import { SKELETON_APP_URLS } from '../../constants'
import { BookADeliveryPopoverContext } from '../../contexts/book-delivery-popover-context'
import { CredentialModalContext } from '../../contexts/credintial-modal-context'
import { NotificationHolder } from '../notification/notification-holder'

type AppProps = {
  locale: string
  messages?: Record<string, MessageFormatElement[]>
  children?: React.ReactNode
}
const simplifiedLayoutRoutes = [
  '/login',
  '/Iceland-BeforeYouGoPages',
  '/checkout/summary',
  '/account/register',
  '/account/bonus-card/topup',
  '/account/verification',
  '/reset'
]

const App: TObservablePage<AppProps> = observer(({ children, messages, locale }: AppProps) => {
  const { hash, pathname } = useLocation()
  const customerStore = useCustomerStore()
  const { isExact: simplifiedLayout } = useRouteMatch(simplifiedLayoutRoutes) || { isExact: false }
  const isAccountPage = pathname.includes('/account/')
  const { stickyBanners, validStickyBanners, params, fetchItemsByContentType } =
    useContentStore()
  const isVisualisation = params?.isContentVisualization
  const [showStickyBanner, setShowStickyBanner] = useState(false)
  const [sentryUserSet, isSentryUserSet] = useState<string | undefined>(undefined)

  // Send user info with sentry errors
  useEffect(() => {
    if (!customerStore.isRegistered && sentryUserSet) {
      // Clear the user state when logged out
      Sentry.setUser(null)
      isSentryUserSet(undefined)
      return
    }
    if (customerStore.isRegistered && (!sentryUserSet || sentryUserSet !== customerStore.customerInfo?.customerId)) {
      Sentry.setUser({ id: customerStore.customerInfo?.customerNo })
      isSentryUserSet(customerStore.customerInfo?.customerNo)
    }
  }, [customerStore.customerInfo])

  useEffect(() => {
    if (simplifiedLayout || isAccountPage) {
      setShowStickyBanner(false)
    } else {
      const timer = setTimeout(() => setShowStickyBanner(true), 10000)
      return () => clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    if (stickyBanners?.length < 1) {
      fetchItemsByContentType(
        'https://www.iceland.co.uk/slot/sticky-banner-slot.json',
        'stickyBanners',
      )
    }
  }, [stickyBanners])

  useEffect(() => {
    if (hash.length === 0) window.scrollTo(0, 0)
  }, [pathname])
  useAutoLogout()
  useUserDataTracking()
  useSentryAnalytics()
  const { isExact: skeletonApp } = useRouteMatch(SKELETON_APP_URLS) || {
    isExact: false,
  }
  if (skeletonApp) {
    return <>{children}</>
  }
  return (
    <IntlProvider
      onError={(err) => {
        if (err.code === 'MISSING_TRANSLATION') {
          console.warn('Missing translation', err.message)
          return
        }
        throw err
      }}
      locale={locale}
      defaultLocale={locale}
      messages={messages}
    >
        <AlgoliaAnalyticsContext>
          <AlgoliaAnalyticsSetup />
          <CredentialModalContext>
            <BookADeliveryPopoverContext>
              {isVisualisation ? (
                <Box display="flex" flexDirection="column" flex="1" bg="gray50">
                  {children}
                </Box>
              ) : (
                <Box display="flex" flexDirection="column" flex="1" bg="gray50">
                  {simplifiedLayout ? (
                    <SimpleHeader customerIsRegistered={customerStore.isRegistered} />
                  ) : (
                    <Header />
                  )}
                  <NotificationHolder />
                  {children}
                  {simplifiedLayout ? <SimplyFooter /> : <Footer />}
                  {stickyBanners && showStickyBanner && validStickyBanners ? <Slot data={validStickyBanners[0] as SlotContentItem} key={validStickyBanners[0]?._meta?.deliveryKey} /> : null}
                  <MergeBasketsModal />
                  <EditOrderExpiredModal />
                  <Chat />
                </Box>
              )}
            </BookADeliveryPopoverContext>
          </CredentialModalContext>
        </AlgoliaAnalyticsContext>
    </IntlProvider>
  )
})
App.shouldGetProps = () => typeof window === 'undefined'
App.getProps = async ({ api, store, location, res }) => {
  const { contentStore, globalStore } = store
  const locale = globalStore?.locale

  if (SKELETON_APP_URLS.includes(location.pathname)) {
    return { locale }
  }
  if (!contentStore.params?.isContentVisualization) {
    await flowResult(contentStore.buildRoutingMapAndNavMenu())
    await flowResult(contentStore.fetchHierarchy('footer-links', 3))
    await flowResult(
      contentStore.fetchItemsByContentType(
        'https://www.iceland.co.uk/slot/sticky-banner-slot.json',
        'stickyBanners',
      ),
    )
    await flowResult(contentStore.fetchItemByKey('search'))
    await flowResult(
      globalStore.getCustomSitePreferencesByIds([
        'orderEditModeDurationInMinutes',
        'deliveryEditOrderExpirationTime',
        'deliveryEditOrderMaxDaysAfterAuth',
        'currentOrdersPeriod',
        'numberOfStops',
        'showMapMinutesAfterDeliverySlotEnd',
        'showMapMinutesBeforeDeliverySlotStart',
        'disableOrderEditOnSameDay',
        'BRAINTREE_Merchant_Account_IDs',
        'BRAINTREE_Tokenization_Key',
        'BRAINTREE_MAX_saved_card',
        'BRAINTREE_savedPaypalCheckoutEnabled',
        'BRAINTREE_Vault_Mode',
        'BRAINTREE_3DSecure_Enabled',
        'BRAINTREE_3DSecure_Skip_Client_Validation_Result',
        'disableDefaultPaymentMethods',
        'BRAINTREE_savedPaypalTopUpEnabled',
        'BRAINTREE_savedCardEnabled',
        'PP_Topup_Enabled',
        'BRAINTREE_PAYPAL_VAULTFLOW',
        // Uncomment this when Iceland team implements those custom preferences
        // 'BRAINTREE_PAYPAL_Billing_Agreement_Description'
        'reCaptchaEnable',
        'reCaptchaEnableExternal',
        'baglessStores',
        'promoModalPageSize',
        'FeefoSupplierMerchantIdentifier',
        'showMixandMatchTextOnMultibuyBadge',
        'showMultibuyPriceBadge',
        'oneTrustEnabled',
        'oneTrustId',
        'navAppLinkAndroid',
        'navAppLinkApple',
        'SubstituteToggleEnabled',
        'SubstituteToggleMemoryEnabled',
        'LiveSoftReservationDurationSameDay',
        'LiveSoftReservationDurationSameDayEnabled',
        'LiveSoftReservationDuration',
        'liveChatEnabled',
        'liveChatPages',
        'SmallBasketChargeEnabled',
        'MinOrderValueSameDay',
        'isPdpTopRightTileBadgeEnabled',
        'isPdpMiddleRightTileBadgeEnabled',
        'isPdpBottomRightTileBadgeEnabled',
        'globalAllergenWords',
        'deliveryShowSameDayFirst',
        'reorderInfoModal',
        'bonusCardTopUpMaxValue',
        'enableAddressIdEdit',
        'autoLogout',
        'allowModifyFirstName',
        'allowModifyLastName',
        'allowModifyDOB',
        'allowModifyContactNumber',
        'enableTroubleLoggingInEmail',
        'slotNotReleased',
        'enableSeasonalOpeningHours',
        'seasonalOpeningHoursText',
        'selfServeEnabled',
        'selfServeNumberOfDaysAfterDelivery',
        'selfServeNumberOfHoursAfterDeliverySlot',
        'selfServeNumberOfMinutesAfterLastRequest',
        'searchDelayMilliseconds',
        'searchMinQueryLength'
      ]),
    )
  }

  if (res) {
    res.set('Cache-Control', 'public, must-revalidate, max-age=900')
    globalStore.setAffiliateTrackingData(res.getHeaders())
  }
  return {
    locale,
  }
}
export default Sentry.withProfiler(App)
