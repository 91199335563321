import React, {useEffect, useRef} from 'react'
import {ContentBody} from 'dc-delivery-sdk-js'
import {observer} from 'mobx-react-lite'
import {Box} from '../../vanilla'
import {SlotComponent} from '../../types/ui'
import {CmsImage} from './cms-image'
import {CTA, Image, ImageLink} from '../../types/cms'
import {useSelectPromotionTracking} from '../../analytics/promotion-tracking'
import ReactDOM from 'react-dom'
import {CTAButton} from '../../vanilla/components/CTAButton'
import {heroBannerCtaButton} from './styles.css'

export interface HeroBannerProps extends ContentBody {
  image: {
    images: Image[]
  }
  imageLink: ImageLink
  isFullWidth: boolean
  cta?: CTA
}

const HeroBannerContainer: SlotComponent<HeroBannerProps> = ({content, index, heroBannerCarouselSlotGroupName}) => {
  const componentRef = useRef(null)

  let creativeSlot = 'Hero Banner';

  const promotion = {
    creative_name: content?.image?.images?.[0]?.data?.altText ?? 'Banner missing alt text',
    creative_slot: `${heroBannerCarouselSlotGroupName ?? content?.slotGroupName} + ${content?._meta?.name}` ?? creativeSlot,
    promotion_id: content?.imageLink ?? 'Banner without link',
    promotion_name: content?.cta?.ctaText ?? 'Banner without CTA',
    link_position:  index ?? 1
  }

  useEffect(() => {
    const componentNode = ReactDOM.findDOMNode(componentRef.current)
    if (componentNode) {
      const parentNode = componentNode.parentNode as HTMLElement
      if (parentNode && parentNode.classList.contains('hero-banner-carousel-item')) {
        creativeSlot = 'Hero Banner - Carousel'
      }
    }
  }, [])

  const sendSelectPromotion = useSelectPromotionTracking()

  if (!content?.image?.images?.length) {
    return null
  }

  return (
    <Box
      width="full"
      style={{maxWidth: '1600px'}}
      position="relative"
      marginX="auto"
      ref={componentRef}
    >
      <CmsImage
        images={content.image.images}
        imageLink={content.imageLink}
        width="full"
        queryParams={[
          {w: 1600, fmt: 'auto', qlt: 'default'}, 
          {w: 3200, fmt: 'auto', qlt: 'default'}
        ]}
        onClick={() => sendSelectPromotion(promotion)}
      />
      {content?.cta && content.cta.ctaText && (
        <CTAButton cta={content.cta} extraClassName={heroBannerCtaButton}
          onClickCapture={() => sendSelectPromotion(promotion)} />
      )}
    </Box>
  )
}

export const HeroBanner = observer(HeroBannerContainer)
