import "app/pages/cart/partials/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/pages/cart/partials/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VV7Y6qMBD9v0/R5GYTzU0NfoAu/tk3uSm0QBVatlRx782++x3aioUlusZEI52ZnnPmzLDIwg8WHZfo3wtCLae6iNEyCF73L18vC3u2Mmc5qWO0CuoL2sCXd7w2x5kUGjf8L4P0sDtHKJWlVDH69ZZ0Hy9jExfyzJTJ0+yiMWWpVERzKWJ0EpSpkgvmJYQ+ut1qEfrwomerbU1oRS74ync7rLgzAZQ3dUk+Y5SVzPDpfjHliqW2NvA7VcLLe3suD6GaUMpFDggiK5kReSgv+VlRJdvuMSl5LnAKzWBC22jcaKJ0d5hIBWpgLWtcskxjRSg/NTE6EzXD+M9y126jpN18zEfBiufFo2iSHnMlQW7s2j4MW53nHqfEcHKBbcE125uOqJwLAw26bAVxzwwC97Cvkk4rY4GDV8GqjSw5nYLS80uk1rK6q0c9EX9Xkvpnkqx9Seg0mcOp0Tz7vHW0qUnKcMJ0y5jx0GBmJwVzZgEsLcQNfBd4CNhTRhtZt6+S2W1gfFcoLo7mEoRkcoAKOOO6GwChCRd7N4UFs2CXUWDhutojEj2ypJTpcYL6tQ45aekhyg2ib67wCqzcDd2e6NGMeroxPTX7xcwYsIB+MHUbuoaV2e1pf33xlKyP+30VxxpxvC24P1mTvjeLunUkE1nShwwO4wU/rLuufB8fp+naC2DQq8ZtJSZol/ZeMcoJalIF/BARFM0q8K/rzDaC1sxNSVc/MH9ABtlwpxwrYdmfzQqBHprtkZIynYXBK/oN7oAK3dnXrcjSFXG39NF4Mnrtov1X3NY6ZiRnGAQ2t+d1n0w4gWPTvd4MkiuU7/N7c4GlO2xINSawddd4r7xhxnLtLurQTg+ACwFq/wHTCxDXMggAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionBoxContainer = 'f5qe6k1';
export var addPromocodeActionContainer = 'f5qe6k0';
export var cartItemDetails = 'f5qe6k5';
export var cartItemDetailsName = 'f5qe6k6';
export var cartItemImage = 'f5qe6k7';
export var editModeActionBoxContainer = 'f5qe6k2';
export var editModeBasketActionText = 'f5qe6k4';
export var editModePageSubtitle = 'f5qe6k3';
export var freeGiftBottom = 'f5qe6ke';
export var freeGiftBottomContainer = 'f5qe6kc';
export var freeGiftBottomContent = 'f5qe6kd';
export var freeGiftDetails = 'f5qe6kh';
export var freeGiftDisclaimerContainer = 'f5qe6kk';
export var freeGiftHeader = 'f5qe6ka';
export var freeGiftImage = 'f5qe6kf';
export var freeGiftNote = 'f5qe6kj';
export var freeGiftPrice = 'f5qe6ki';
export var freeGiftProductCard = 'f5qe6k9';
export var freeGiftProductCardContainer = 'f5qe6k8';
export var freeGiftQuantity = 'f5qe6kg';
export var freeGiftText = 'f5qe6kb';