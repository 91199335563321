import React from 'react'
import {observer} from 'mobx-react-lite'
import {Box, Button,BaseButton, Stack, useDisclosure, Text, Flex} from '../../vanilla'
import {Modal} from '../modal'
import {CloseIcon, ExclamationCircleIcon} from '../icons'
import {discardMessageBlock, modalFooter, modalFooterButton, titleUnderline} from './styles.css'
import {useCustomerStore, useOrderStore} from '../../store/hooks/useStore'
import {isMobile} from '../../utils/utils'
import useNavigation from '../../hooks/use-navigation'
import {useLocation} from 'react-router-dom'
import { Link } from '../link'
interface ExitOrderEditModeModal extends ReturnType<typeof useDisclosure> {}

export const ExitOrderEditModeModal = observer(function ExitOrderEditModeModal(
  props: ExitOrderEditModeModal
) {
  const {endOrderEdit} = useOrderStore()
  return (
    <Modal size="full-lg" closeIcon={false} {...props}>
      <Stack width="full" spacing="0px">
        <Flex justify="flex-end" width="full" padding="16px">
          <Button variant="unstyled" onClick={() => props.onClose()}>
            <CloseIcon boxSize="28px" color="gray800" />
          </Button>
        </Flex>
        <Box>
          <Text
            variant="heading5"
            lineHeight="shorter"
            marginBottom={['12px', '20px']}
            display="block"
            as="span"
            align="center"
          >
            Discard Changes
          </Text>
          <Box className={titleUnderline} />
        </Box>

        <Flex
          align="stretch"
          border="1px"
          borderColor="promotionColorDark"
          borderRadius="base"
          className={discardMessageBlock}
        >
          <Flex
            align="center"
            width="52px"
            bg="promotionColorDark"
            flexShrink="0"
            height="auto"
            justify="center"
          >
            <ExclamationCircleIcon color="white" boxSize="20px" />
          </Flex>
          <Box padding="12px">
            <Text variant="text3">
              By clicking &apos;Discard Changes&apos; any changes made to your order will be lost
              and you will be returned to your &apos;Order History&apos;
            </Text>
          </Box>
        </Flex>

        <Flex
          gap="20px"
          align="center"
          justify={['flex-start', 'center']}
          direction={['column-reverse', 'row']}
          paddingX={['12px', '28px']}
          paddingTop="12px"
          paddingBottom="28px"
          className={modalFooter}
        >
          <Button
            width="full"
            size="sm"
            variant="outlineDark"
            onClick={() => props.onClose()}
            extraClassName={modalFooterButton}
          >
            Resume Editing
          </Button>
          <BaseButton
          as={Link}
          to={'/account/order-history'}
            width="full"
            size="sm"
            variant="primary"
            onClickCapture={() => {
              endOrderEdit()
              props.onClose()
            }}
            extraClassName={modalFooterButton}
          >
             <Text variant="unstyled" fontSize="inherit" fontWeight="inherit">
          Discard Changes
        </Text>
          </BaseButton>
        </Flex>
      </Stack>
    </Modal>
  )
})

interface ExitOrderEditModeSignOutModal extends ReturnType<typeof useDisclosure> {
  navMenuOnClose?: () => void
}

export const ExitOrderEditModeSignOutModal = observer(function ExitOrderEditModeSignOutModal(
  props: ExitOrderEditModeSignOutModal
) {
  const {endOrderEdit} = useOrderStore()
  const {logout} = useCustomerStore()
  const isMobileFlag = isMobile()
  const navigate = useNavigation()
  const location = useLocation()

  const logoutHandler = async () => {
    await endOrderEdit()
    logout()
    props.onClose()
    if (isMobileFlag) {
      props.navMenuOnClose?.()
    }
    if (location.pathname.includes('account')) {
      navigate('/') // Redirect to home if the user is on the account page
    }
  }

  return (
    <Modal size="lg" closeIcon={false} {...props}>
      <Stack width="full" spacing="0px">
        <Flex justify="flex-end" width="full" padding="16px">
          <Button variant="unstyled" onClick={() => props.onClose()}>
            <CloseIcon boxSize="28px" color="gray800" />
          </Button>
        </Flex>
        <Box>
          <Text
            variant="heading5"
            lineHeight="shorter"
            marginBottom={['12px', '20px']}
            display="block"
            as="span"
            align="center"
          >
            Discard Changes
          </Text>
          <Box className={titleUnderline} />
        </Box>

        <Box marginY="20px">
          <Text variant="text3" align="center">
            By signing out any changes you have made to your order will be lost. Your original order
            will not be affected.
          </Text>
        </Box>

        <Flex
          gap="20px"
          align="center"
          justify={['flex-start', 'center']}
          direction={['column-reverse', 'row']}
          paddingX={['12px', '28px']}
          paddingTop="12px"
          paddingBottom="28px"
          className={modalFooter}
        >
          <Button
            width="full"
            size="sm"
            variant="outlineDark"
            onClick={() => props.onClose()}
            extraClassName={modalFooterButton}
          >
            Resume Editing
          </Button>
          <Button
            width="full"
            size="sm"
            variant="primary"
            onClick={logoutHandler}
            extraClassName={modalFooterButton}
          >
            Sign out and discard
          </Button>
        </Flex>
      </Stack>
    </Modal>
  )
})
