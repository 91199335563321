import React from 'react'
import {observer} from 'mobx-react'
import {Stack, Box, Text, BoxProps} from '../../../vanilla'
import {Link} from 'react-router-dom'
import {useBasketItem} from '../../../store/hooks/useBasketItem'
import {ProductImage} from '../../../components/product-detail/product-image'
import {formatProductName} from '../../../utils/utils'
import {
  freeGiftProductCard,
  freeGiftHeader,
  freeGiftText,
  freeGiftBottomContainer,
  freeGiftBottomContent,
  freeGiftBottom,
  freeGiftQuantity,
  freeGiftDetails,
  freeGiftPrice,
  freeGiftNote,
  freeGiftDisclaimerContainer,
  freeGiftImage,
} from './styles.css'
import { cartItemImage } from '../../../components/cart-item/styles.css'

interface FreeGiftProps extends BoxProps<'div'> {
  id: string
  name: string | undefined
  inDrawer: boolean
}

export const FreeGift = observer(({id, name, inDrawer, ...rest}: FreeGiftProps) => {
  const {product} = useBasketItem(id)
  const img = product?.image_groups?.[0]?.images?.[0] || product?.imageGroups?.[0]?.images?.[0]
  const productLink = `/p/${formatProductName(product?.name)}/${product?.id}.html`

  return (
    <Stack {...rest}>
      <Stack width={'full'} className={freeGiftProductCard}>
        <Stack className={freeGiftHeader}>
          <Stack>
            <Text className={freeGiftText}>FREE GIFT</Text>
          </Stack>
        </Stack>
        <Stack className={freeGiftBottomContainer}>
          <Stack className={freeGiftBottom}>
            <Box as={Link} to={productLink} className={cartItemImage}>
              <ProductImage
               className={freeGiftImage}
                image={img}
                imageAlt={img?.alt}
                isThumbnail={false}
                inDrawer={false}
                marginTop={['12px', '0px']}
              />
            </Box>
            <Stack
              className={freeGiftBottomContent}
              flexDirection={inDrawer ? 'column' : 'row'}
              paddingLeft={inDrawer ? '0px' : ['0px', '40px']}
            >
              <Text
                as={Link}
                to={productLink}
                color={{default: 'gray800', hover: 'gray700'}}
                variant="text3"
                lineHeight="short"
              >
                {name}
              </Text>
              <Stack
                justifyContent={inDrawer ? 'space-between' : 'center'}
                gap={inDrawer ? '20px' : '0px'}
              >
                <Box
                  className={freeGiftDetails}
                  gap={inDrawer ? '40px' : ['52px', '160px']}
                  paddingRight={!inDrawer ? ['0px', '76px'] : '0px'}
                >
                  <Text className={freeGiftQuantity}>1</Text>
                  <Text className={freeGiftPrice} variant="unstyled">
                    FREE
                  </Text>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack className={freeGiftDisclaimerContainer}>
          <Text variant="unstyled" className={freeGiftNote}>
            Free gifts are subject to availability
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
})
