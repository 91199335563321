import {useEffect} from 'react'
import {useGTMDispatch} from '../analytics/react-gtm-hook/index'
import {useCustomerStore} from '../store/hooks/useStore'
import {useBasketStore} from '../store/hooks/useStore'
import {useSoftLogoutData} from '../hooks/use-soft-logout-data'
import {getDeviceType} from '../utils/utils'
import {normalizePhoneNumberToE164} from '../utils/phone-utils'
import {generateCodeChallenge} from 'commerce-api/src/pkce'
import { EncodeType } from 'commerce-api/dist/types/utils'

/**
 * User data tracking hook, watches changes to customer object and 
 * updates data layer in response
 * @returns 
 */
export const useUserDataTracking = () => {

  const {customerInfo, status } = useCustomerStore()
  const sendDataToGTM = useGTMDispatch()
  const {deliveryPostalCode, currentDeliveryStoreId} = useBasketStore()
  const softLogoutData = useSoftLogoutData() 
  
  useEffect(() => {

    if(status != 'done') return

    setTimeout(() => {
      const sendUserData = async () => {
        try {
          const userData = await createUserData(
            {
              customerInfo: customerInfo, 
              postCode: deliveryPostalCode,
              storeId: currentDeliveryStoreId
            },
            softLogoutData
          )
          sendDataToGTM(userData)
        } catch (error) { 
          console.error('useUserDataTracking userEffect error: ' + error.message)
        }
      }
      sendUserData()
    }, 0)

  }, [customerInfo, status, deliveryPostalCode, currentDeliveryStoreId, sendDataToGTM])

  return null
}

/**
 * 
 * @param {*} sourceData Source customer info from Customer store
 * @param {*} softLogoutData Soft logout data to fall back to 
 * @returns 
 */
const createUserData = async (sourceData, softLogoutData) => { 

  const {customerInfo, postCode, storeId } = sourceData  

  let softUserData = {}
  const isSoftLogout = softLogoutData.softLogout

  const hashSensitiveData = async (data) => {
    const hashedData = { ...data }
    const encodeType = EncodeType.HEX

    if (data.logged_in !== 'Logged Out' ) {
      hashedData.first_name = await generateCodeChallenge(data.first_name, encodeType)
      hashedData.last_name = await generateCodeChallenge(data.last_name, encodeType)
      hashedData.email_address = await generateCodeChallenge(data.email_address, encodeType)
      hashedData.phone = await generateCodeChallenge(data.phone, encodeType)
      hashedData.sha256_street = await generateCodeChallenge(data.street, encodeType)
      hashedData.sha256_city = await generateCodeChallenge(data.city, encodeType)
      hashedData.sha256_region = await generateCodeChallenge(data.region, encodeType)
    }

    hashedData.sha256_postal_code = data.postal_code ? await generateCodeChallenge(data.postal_code, encodeType) : data.postal_code

    return hashedData
  }

  if(isSoftLogout) {
    const address = redactAddressBook(softLogoutData.addressBook)

    // Simplified object used to calculate custom status
    const softCustomerOrderStatistics = {
      authType: 'registered',
      c_ordersCount: softLogoutData.orderCount,
      c_legacyOrderCount: softLogoutData.legacyOrderCount 
    }

    softUserData = {
      logged_in: 'Soft logged in', 
      first_name: softLogoutData.firstName,
      email_address: softLogoutData.email,
      phone: preferredAddressPhone(softLogoutData.addressBook) ?? softLogoutData.phone,
      registered: 'Yes',
      last_name: softLogoutData.lastName,
      street: address ? address.street : '',
      city: address ? address.city : '',
      region: address ? address.region : '',
      postal_code: postCode,
      country: 'GB',
      user_id: softLogoutData.customerNo,
      customer_id: softLogoutData.customerNo,
      order_count: softLogoutData.orderCount,
      customer_status: calculateCustomerStatus(softCustomerOrderStatistics)
    }
  }

  const customerId = (customerInfo?.customerNo == undefined) 
    ? customerInfo.customerId 
    : customerInfo.customerNo

  const address = redactAddressBook(customerInfo.addresses)

  const userData = {
    'event' :'userData',
    customer_id: customerId,  
    user_id: customerInfo.customerNo,
    logged_in: customerInfo.authType === 'registered' ? 'Logged In' : 'Logged Out',
    registered: customerInfo.authType === 'registered' ? 'Yes' : 'No',
    first_name: customerInfo.firstName,
    last_name: customerInfo.lastName,
    email_address: customerInfo.login,
    phone: preferredAddressPhone(customerInfo.addresses) ?? customerInfo.phoneHome,
    postal_code: postCode,
    country: 'GB',
    store_id: storeId,
    device_type: getDeviceType(window?.navigator?.userAgent),
    order_count: customerInfo.c_ordersCount,
    customer_status: calculateCustomerStatus(customerInfo),
    street: address ? address.street : '',
    city: address ? address.city : '',
    region: address ? address.region : ''
  }

  const mergedUserData = {...userData, ...softUserData}
  const hashedUserData = await hashSensitiveData(mergedUserData)

  return hashedUserData
}

/**
 * Calculate a customer status classification
 * @param {*} customerInfo 
 * @returns String status description of customer
 */
const calculateCustomerStatus = (customerInfo) => {
 
  if(customerInfo.authType != 'registered') return 'unknown'

  const orderCount = (customerInfo?.c_ordersCount != undefined) ? customerInfo?.c_ordersCount : 0
  const legacyOrderCount = (customerInfo?.c_legacyOrderCount != undefined) ? customerInfo?.c_legacyOrderCount : 0   

  return (orderCount > 0 || legacyOrderCount > 0) ? 'existing' : 'new'

}

/**
 * Parse address book and return shorthand address for advanced conversion
 * @param {*} addresses address book to the evaluate
 * @returns object of shortened customer preferred address object
 */
const redactAddressBook = (addresses) => { 

  if(!addresses?.length > 0) return undefined

  let preferredAddress

  for(let address of addresses) {
    if (address.preferred) {
      preferredAddress = {
        street: address.address1 + (address.address2 ? ` ${address.address2}` : ''),
        city: address.city,
        region: address.stateCode
      }
      break
    }
  }

  return preferredAddress
}

/**
 * Parse address book and return phone number from preferred (primary) address
 * @param {*} addresses address book to evaluate
 * @returns the phone number from preferred address
 */
const preferredAddressPhone = (addresses) => { 

  if (!addresses?.length > 0) return undefined

  let preferredPhone
  
  for (let address of addresses) {
    if (address.preferred) {
      preferredPhone = address.phone
      break
    }
  }

  return normalizePhoneNumberToE164(preferredPhone)
}