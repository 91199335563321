import React, {ReactElement, ReactNode, useCallback, useEffect, useState} from 'react'
import {Box, BoxProps, Container, Flex, Stack, Text} from '../../vanilla'
import {activeTabRecipe, tabRecipe} from './styles.css'
import {StackProps} from '../../vanilla/components/Stack'
import {Link} from '../link'
import {useLocation} from 'react-router-dom'
import {Markdown} from '../cms/Markdown'

interface TabSwitcherProps {
  title: string | ReactNode
  index: number
  active: boolean
  onTabChange: (index: number) => void
  id?: string
  mobileLayoutAllowed?: boolean
  variant?: string
}

const TabSwitcher = (props: TabSwitcherProps) => {
  const {title, index, onTabChange, active, id, variant, mobileLayoutAllowed} = props
  const tabVariant = !variant ? 'primary' : 'secondary'

  const className = tabRecipe({variant: tabVariant})
  const activeClassName = activeTabRecipe({variant: tabVariant})

  const handleOnSwitcherClick = useCallback(() => {
    onTabChange(index)
  }, [onTabChange, index])

  return (
    <Box
      as="li"
      position="relative"
      paddingX="24px"
      paddingY="20px"
      onClick={handleOnSwitcherClick}
      className={active ? activeClassName : className}
      role="tab"
      {...(mobileLayoutAllowed ? {width: ['full', 'auto']} : {width: 'auto'})}
    >
      {typeof title === 'string' ? (
        <Text
          textAlign="center"
          lineHeight="shorter"
          variant="unstyled"
          {...(id ? {as: Link, href: `#${id}`} : {})}
        >
          {title}
        </Text>
      ) : (
        title
      )}
    </Box>
  )
}

interface TabPanelProps extends Omit<BoxProps<'div'>, 'title'> {
  children: string | ReactNode
  title: string | ReactNode
}

export const TabPanel = (props: TabPanelProps) => {
  const {children, title, ...rest} = props
  return <Box {...rest}>{children}</Box>
}

interface TabsProps extends StackProps<'div'> {
  id: string
  variant?: string
  defaultSelectedIndex?: number
  mobileLayoutAllowed?: boolean
  children: ReactElement<TabPanelProps>[]
  switcherContent?: ReactNode
  selectedIndex?: number
  onTabChange?: (index: number) => void
}

interface TabContent {
  tabID: string
  tabText: string
  tabTitle: string
}

interface TabProps {
  content: TabContent
}
export const Tab = ({content}: TabProps ) => {
  const {tabID, tabText, tabTitle} = content
  return (
    <TabPanel id={tabID} title={tabTitle}>
      <Box paddingTop="48px">
        <Markdown
          markdown={tabText}
          componentProps={{
            p: {
              marginBottom: '16px',
              variant: 'text3',
            },
            h1: {
              marginBottom: '8px',
              lineHeight: 'tall',
              variant: 'unstyled',
              fontSize: 'xl',
              fontWeight: 'bold',
              as: 'h3',
            },
            a: {
              variant: 'text3',
              textDecoration: 'underline',
            }
          }}
        />
      </Box>
    </TabPanel>
  )
}

export const Tabs = (props: TabsProps) => {
  const {
    children,
    defaultSelectedIndex,
    mobileLayoutAllowed,
    id,
    variant,
    switcherContent,
    selectedIndex,
    onTabChange,
    ...rest
  } = props

  const location = useLocation()

  const [internalActiveTabIndex, setInternalActiveTabIndex] = useState<number>(defaultSelectedIndex ?? 0)

  const activeTabIndex = selectedIndex !== undefined ? selectedIndex : internalActiveTabIndex

  const handleTabChange = (index: number) => {
    if (selectedIndex === undefined) {
      setInternalActiveTabIndex(index)
    }
    if (onTabChange) {
      onTabChange(index)
    }
  }

  useEffect(() => {
    const {hash} = location

    if (hash) {
      const childIndexByHash = children.findIndex((child) => child.props.id === hash.substring(1))

      if (childIndexByHash !== -1) setInternalActiveTabIndex(childIndexByHash)
    }
  }, [])

  return (
    <Stack {...rest} width="full">
      <Box bg={variant ? 'white' : 'transparent'}>
        <Container style={!variant ? {padding: '0px', width: 'auto'} : {}}>
          <Flex
            as="ul"
            role="tablist"
            id={id}
            width="full"
            borderBottom={variant ? 'none' : '1px'}
            borderColor="gray200"
            flexWrap={['nowrap', mobileLayoutAllowed ? 'wrap' : 'nowrap']}
            flexDirection={[mobileLayoutAllowed ? 'column' : 'row', 'row']}
          >
            {children.map((panel, index) => {
              const {title, id} = panel.props

              return (
                <TabSwitcher
                  key={`${id}-tab-switcher-${index}`}
                  active={activeTabIndex === index}
                  title={title}
                  index={index}
                  id={id}
                  variant={variant}
                  mobileLayoutAllowed={mobileLayoutAllowed}
                  onTabChange={handleTabChange}
                />
              )
            })}
          </Flex>
          {switcherContent ? switcherContent : null}
        </Container>
      </Box>
      <Container style={!variant ? {padding: '0px', width: '100%', wordWrap: 'break-word'} : {}}>
        <Box id={`${id}Content`}>{children[activeTabIndex]}</Box>
      </Container>
    </Stack>
  )
}