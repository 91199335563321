import {useStoreContext} from '../../contexts'
import type {RootStore} from '../RootStore'

export const useStore = (): RootStore => useStoreContext()

export const useCustomerStore = () => {
  const rootStore = useStore()
  const customerStore = rootStore!.customerStore
  return customerStore
}

export const useBasketStore = () => {
  const rootStore = useStore()
  const basketStore = rootStore!.basketStore
  return basketStore
}

export const useCategoryStore = () => {
  const rootStore = useStore()
  const categoryStore = rootStore!.categoryStore
  return categoryStore
}

export const useContentStore = () => {
  const rootStore = useStore()
  const contentStore = rootStore!.contentStore
  return contentStore
}

export const useProductStore = () => {
  const rootStore = useStore()
  const productStore = rootStore!.productStore
  return productStore
}

export const useGlobalStore = () => {
  const rootStore = useStore()
  const globalStore = rootStore!.globalStore
  return globalStore
}

export const usePromotionStore = () => {
  const rootStore = useStore()
  const promotionStore = rootStore!.promotionStore
  return promotionStore
}

export const useStoreDetailsStore = () => {
  const rootStore = useStore()
  const storeDetailsStore = rootStore!.storeDetailsStore
  return storeDetailsStore
}

export const useOrderStore = () => {
  const rootStore = useStore()
  const orderStore = rootStore!.orderStore
  return orderStore
}

export const usePreviewDebugStore = () => {
  const rootStore = useStore()
  const previewDebugStore = rootStore!.previewDebugStore
  return previewDebugStore
}

export const useAlertsStore = () => {
  const rootStore = useStore()
  const alertsStore = rootStore!.alertsStore
  return alertsStore
}

export const useRefundStore = () => {
  const rootStore = useStore()
  const refundStore = rootStore!.refundStore
  return refundStore
}