import {ShopperBaskets, ShopperOrders} from 'commerce-api'
import {OmitFromKnownKeys} from '../../store/utils'
import {PaymentInstrument} from '../commerce'
import {ProductItem} from '../../store/BasketStore'
import {Nullable} from '../utils'
import {AddressFormFields} from '../forms'

export type CustomerBasket = ShopperBaskets.Basket
export type BasketResult = ShopperBaskets.BasketsResult
export type Order = ShopperOrders.Order
export type OrderAddress = ShopperOrders.OrderAddress
export type TProductItem = ShopperBaskets.ProductItem

export interface StoreBasket extends OmitFromKnownKeys<ShopperBaskets.Basket, 'productItems'> {
  productItems?: ProductItem[]
  applicableShippingMethods?: ShopperBaskets.ShippingMethodResult['applicableShippingMethods']
  defaultShippingMethodId?: string
  paymentInstruments?: PaymentInstrument[]
  orderDiscount?: number
}

export interface PostcodeDeliveryStatusResponse {
  data: {
    code: boolean
  }
}

export interface TimeSlot {
  available: boolean
  availableBeforeAlcoholRestrictions: boolean
  endHour: string
  firstAvailable: boolean
  hasFreeDelivery: boolean
  id: string
  notReleasedStatus: boolean
  orderProductRestrictions: Array<any>
  restrictedProductClasses: Array<string>
  sameDayCutOffDate: string
  sameDayCutOffEditDate: string
  sameDayPastCutOff: boolean
  shippingCost: number
  shippingData: Array<any>
  startHour: string
  storeId: string
  vipSlot?: boolean
  windowEndTime: string
  windowStartTime: string,
  smallBasketCharge: number,
  smallBasketChargeThreshold: number
}

export interface DeliverySlot {
  day: string
  dayText: string
  hasAvailableSlots: boolean
  hasVipSlots?: boolean
  isToday: boolean
  month: string
  slots: Array<TimeSlot>
}

export type PartialProductItem = {
  product: ProductItem | undefined
  quantity: number
  event?: string
  isSearch?: boolean
}

export type PreviouslyRemovedItem = {
  pid: string
  qty: number
}

export interface BeforeYouGoPage {
  categoryID: string
  description: string
  hideProductsInBasket: boolean
  ID: string
  minProducts: number
  pageType: string
  paginationEnabled: boolean
  productsPerPage: number
  searchTerm: string
  showBadges: boolean
  showPromotions: boolean
  showRating: boolean
  title: string
}

export type BeforeYouGoPages = BeforeYouGoPage[]

export interface BeforeYouGoPromo {
  ID: string
  details: {}
  finished: boolean
  groups: Array<{
    cartQty: number
    requiredQty: number
  }>
  imageURL: string
  maxApp: number
  name: string
  promoQtyRatio: number
  remainingQty: number
  type: string
  isCompleted?: boolean
  exceededFirstCycle?: boolean
  removeNearnessMessage?: boolean
}

export interface MissedOffers {
  incompleteOffers: number
  promotions: {
    [key: string]: BeforeYouGoPromo
  }
  totalOffers: number
  completedPromos: {
    [key: string]: BeforeYouGoPromo
  }
}

export interface ReserveSlotResponse {
  result?: {
    code?: string
    message?: string
    shippingMethod?: string
    slotTimeout?: string
  }
  basket?: ShopperBaskets.Basket
  incompleteOffers?: MissedOffers
  c_customerGroups?: string[]
}

export interface BasketPromotionsThreshold {
  ID: string
  enabled: boolean
  progressText: string | null
  completedText: string | null
  popoverTitle: string | null
  popoverText: string | null
  popoverLink: string | null
  popoverLinkText: string | null
  showOnComplete: boolean
  thresholdSource: string | null
  thresholdValue: number
  svg: string | null
  remaining: number | null
  complete: boolean
  progress: number | null
}

export type BasketPromotionsThresholdResponse = {
  promoThresholds: (BasketPromotionsThreshold & Record<string, unknown>)[]
}

export interface SubmitOrderData {
  btData: Nullable<Record<string, any>>
  userData?: Nullable<string | object | undefined>
  threeDSecureData?: Nullable<Record<string, any>>
  usingSavedCard?: boolean
  saveCreditCard?: boolean
  saveNewAddress?: boolean
  billingAddress?: AddressFormFields
}

export enum BasketMergeTypes {
  Login = 'login',
  SoftLogin = 'softLogin',
  Logout = 'logout',
  Edit = 'edit',
  Reorder = 'reorder'
}
