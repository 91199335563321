import React from 'react'
import {SlotComponent} from '../../types/ui'
import {TextAlignment} from '../../types/cms'
import {Markdown} from './Markdown'
import {Stack, Text, Box} from '../../vanilla'
import {Sprinkles} from '../../vanilla/sprinkles.css'
import {TabPanel, Tabs} from '../tabs'
import {tableText, richTextImg} from './styles.css'

interface ContentTabGroupCmsContent {
  headline: string
  headingAlignment: Exclude<TextAlignment, 'Right'>
  tabList: {tabTitle: string; tabID: string; tabText: string}[]
}

export const ContentTabGroup: SlotComponent<ContentTabGroupCmsContent> = ({content}) => {
  const {headline, headingAlignment, tabList, _meta} = content

  if (!tabList.length) return null

  return (
    <Stack
      className="content-tab-group"
      align="flex-start"
      paddingX="28px"
      paddingBottom="28px"
      paddingTop="16px"
      spacing="0px"
      bg="white"
    >
      {headline && (
        <Box width="full" borderBottom="1px" borderColor="gray200" paddingY="16px">
          <Text
            variant="heading1"
            lineHeight="base"
            width="full"
            as="h2"
            align={headingAlignment.toLowerCase() as Sprinkles['textAlign']}
          >
            {headline}
          </Text>
        </Box>
      )}
      <Tabs id={_meta.deliveryId} mobileLayoutAllowed>
        {tabList.map(({tabTitle, tabText, tabID}) => {
          return (
            <TabPanel id={tabID} title={tabTitle}>
              <Box paddingTop="48px">
                <Markdown
                  markdown={tabText}
                  componentProps={{
                    p: {
                      marginBottom: '16px',
                      variant: 'text3',
                    },
                    h1: {
                      marginBottom: '8px',
                      lineHeight: 'tall',
                      variant: 'unstyled',
                      fontSize: 'xl',
                      fontWeight: 'bold',
                      as: 'h3',
                    },
                    table: {
                      display: 'block',
                      marginBottom: '16px',
                      overflowX: 'auto'
                    },
                    th: {
                      border: '2px',
                      borderColor: 'black',
                      borderStyle: 'solid',
                      padding: '10px',
                      className: tableText,
                      variant: 'text3'
                    },
                    td: {
                      border: '2px',
                      borderColor: 'black',
                      borderStyle: 'solid',
                      padding: '10px',
                      className: tableText,
                      variant: 'text3'
                    },
                    img: {
                      className: richTextImg
                    },
                    a: {
                      variant: 'text3',
                      textDecoration: 'underline',
                    }
                  }}
                />
              </Box>
            </TabPanel>
          )
        })}
      </Tabs>
    </Stack>
  )
}
