import React, {useEffect, useState} from 'react'
import {BoxProps, Button, Flex, Text} from '../../vanilla'
import {MinusIcon, PlusIcon} from '../icons'
import {quantityInputButton, quantityInputRecipeButton, quantityInputRecipeInput} from './style.css'
import classNames from 'classnames'
import {
  useBasketStore,
  useCustomerStore,
  useOrderStore
} from '../../store/hooks/useStore'
import {ProductItem} from '../../store/BasketStore'
import {useRemoveFromCartTracking} from '../../analytics/click-event-tracking'
import {useAddToCartTracking} from '../../analytics/click-event-tracking'
import {useSlotExpiration} from '../../store/hooks/useSlotExpiration'
import {Nullable} from '../../types/utils'
import {RestrictionModal} from '../update-cart-quantity'

export interface QuantityInputProps extends Omit<BoxProps<'div'>, 'onChange' | 'value'> {
  product?: ProductItem
  quantity: number
  setQuantity: React.Dispatch<React.SetStateAction<number>>
  size?: 'md' | 'sm'
  maxQuantity?: number
  variant?: 'primary' | 'outlineDark'
  disabled?: boolean
  cartLocation?: string
  viewingCategoryId?: Nullable<string>
  searchQuery?: Nullable<string>
  hitItemIndex?: number
  restrictionModal?: RestrictionModal
}

const QuantityInput = ({
  product,
  quantity,
  setQuantity,
  size = 'md',
  maxQuantity,
  disabled,
  variant = 'primary',
  cartLocation,
  viewingCategoryId,
  searchQuery,
  hitItemIndex,
  restrictionModal,
  ...rest
}: QuantityInputProps) => {
  const [isMaxLimitReached, setIsMaxLimitReached] = useState(false)
  const [incrementClickedAtMaxQuantity, setIncrementClickedAtMaxQuantity] = useState(false)
  const [clickedQuantity, setClickedQuantity] = useState(quantity)
  const basketStore = useBasketStore()
  const {isReservationExpired, handleEditReservationExpired} = useSlotExpiration()
  const customerStore = useCustomerStore()
  const sendRemoveFromCartData = useRemoveFromCartTracking()
  const {editMode} = useOrderStore()

  useEffect(() => {
    setClickedQuantity(quantity)
  }, [quantity])

  const updateQuantity = (e: React.MouseEvent, isIncrease: boolean) => {
    customerStore.setLastActiveToNow()
    e.preventDefault()
    e.stopPropagation()

    if (editMode && isReservationExpired()) {
      // If in edit mode and slot has expired send to order history and don't add to basket
      handleEditReservationExpired()
      return
    }

    customerStore.setLastActiveToNow()

    // Increase the current quantity state
    const newQuantity = isIncrease ? quantity + 1 : quantity - 1

    // Stop increase from happening if the quantity is already at max quantity
    if (isIncrease && maxQuantity && newQuantity > maxQuantity) {
      setIncrementClickedAtMaxQuantity(true)
      setIsMaxLimitReached(true)
      setTimeout(() => {
        setIsMaxLimitReached(false)
      }, 2000)

      return
    } else if (!isIncrease && incrementClickedAtMaxQuantity) {
      setIncrementClickedAtMaxQuantity(false)
    }

    const productIndex = basketStore.getUpdateProductIndex(product?.id)

    if (productIndex >= 0) {
      basketStore.updateProductToUpdateProductQuantity(product?.id, newQuantity)
    } else {
      // If in edit mode and slot has expired send to order history and don't add to basket
      handleEditReservationExpired()
      basketStore.addProductToUpdate({product: product, quantity: newQuantity})
    }

    setQuantity(newQuantity)
  }

  const buttons = quantityInputRecipeButton({
    size,
    variant,
    visible: !incrementClickedAtMaxQuantity,
  })
  const input = quantityInputRecipeInput({size, variant})
  const sendAddToCartData = useAddToCartTracking(basketStore.basket)

  return (
    <Flex alignItems="center" flexDirection="column" {...rest}>
      <Flex>
        <Button
          extraClassName={classNames(quantityInputButton, buttons)}
          aria-label="Decrease quantity"
          flexShrink="0"
          variant={variant}
          onClick={(e) => {
            updateQuantity(e, false)
            sendRemoveFromCartData(
              product,
              cartLocation,
              viewingCategoryId,
              searchQuery,
              hitItemIndex,
            )
          }}
          disabled={disabled}
          size="sm"
          iconLeft={
            <MinusIcon
              style={{
                height: size === 'sm' ? 10 : 12,
                width: size === 'sm' ? 10 : 12,
                pointerEvents: 'none',
              }}
            />
          }
        />
        <Text
          variant="unstyled"
          className={input}
          flexShrink="1"
          alignSelf="stretch"
          style={variant === 'outlineDark' ? {} : {color: '#0097cc'}}
        >
          {isMaxLimitReached ? 'Max Qty' : `${clickedQuantity}`}
        </Text>

        <Button
          variant={variant}
          extraClassName={classNames(quantityInputButton, buttons)}
          aria-label="Increase quantity"
          flexShrink="0"
          onClick={(e) => {
            updateQuantity(e, true)
            sendAddToCartData(product, cartLocation)
          }}
          disabled={disabled}
          size="sm"
          iconLeft={
            <PlusIcon
              style={{
                height: size === 'sm' ? 10 : 12,
                width: size === 'sm' ? 10 : 12,
                pointerEvents: 'none',
              }}
            />
          }
        />
      </Flex>
      {cartLocation !== 'Quick Search' && isMaxLimitReached && (
        <Text color="accent0"> Maximum reached</Text>
      )}
    </Flex>
  )
}

export default QuantityInput
