import React, {useState, useEffect} from 'react'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {Box, Button, Select, Stack, Text} from '../../vanilla'
import {FieldName} from 'react-hook-form/dist/types/fields'
import {GoogleRecaptcha} from '../grecaptcha'
import {ObjectValues} from '../../types/utils'
import {
  BonusCardFieldSet,
  GeneralFieldSet,
  InStoreHomeDeliveryFieldSet,
  OnlineFieldSet,
  ProductFieldSet,
} from './field-sets'
import {Status, StatusMap} from '../../types/commerce'
import LoadingOverlay from '../loading-overlay'
import {Alert} from '../alert'
import {ContactUsFormService} from '../../services/contact-us-form'
import {formFieldsContainer} from './styles.css'
import { useGlobalStore } from '../../store/hooks/useStore'
import { DateUtils } from '../../utils/date-utils'

export const FEEDBACK_TYPE_VALUES = {
  EMPTY: '',
  IN_STORE_HOME_DELIVERY: 'In-Store Home Delivery',
  GENERAL: 'General',
  BONUS_CARD: 'Bonus Card',
  ONLINE: 'Online',
  PRODUCT: 'Product',
} as const

export type FeedbackValues = ObjectValues<typeof FEEDBACK_TYPE_VALUES>

const feedbackTypeOptions = [
  {
    label: 'Select feedback type',
    value: FEEDBACK_TYPE_VALUES.EMPTY,
  },
  {label: 'In-Store Home Delivery', value: FEEDBACK_TYPE_VALUES.IN_STORE_HOME_DELIVERY},
  {label: 'General', value: FEEDBACK_TYPE_VALUES.GENERAL},
  {label: 'Bonus Card', value: FEEDBACK_TYPE_VALUES.BONUS_CARD},
  {label: 'Online', value: FEEDBACK_TYPE_VALUES.ONLINE},
  {label: 'Product', value: FEEDBACK_TYPE_VALUES.PRODUCT},
]

const DEFAULT_FEEDBACK_TYPE_VALUE = feedbackTypeOptions[0].value
export const DynamicFormContent = () => {
  const [submitStatus, setSubmitStatus] = useState<Status>(StatusMap.IDLE)
  const [recaptchaEnabled, setRecaptchaEnabled] = useState(false)
  const {getCustomSitePreferenceById, isCustomSitePreferenceSet, customSitePreferences } = useGlobalStore()

  useEffect(() => {
    (async function () {
      if (!isCustomSitePreferenceSet('reCaptchaEnablePassphrase')) {
        await getCustomSitePreferenceById('reCaptchaEnablePassphrase')
      }
      setRecaptchaEnabled(customSitePreferences['reCaptchaEnablePassphrase'] === true)

      if(!recaptchaEnabled) {
        setCaptchaValidationSuccess(true)
      }
    }())
  }, [])

  const [captchaValidationSuccess, setCaptchaValidationSuccess] = useState(false)

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      myquestion: DEFAULT_FEEDBACK_TYPE_VALUE,
    },
  })

  const handleFeedbackTypeChange = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    form.setValue(e.currentTarget.name as FieldName<{myquestion: string}>, e.currentTarget.value as FeedbackValues)
  }

  const handleSubmit: SubmitHandler<Record<string, string>> = async (data) => {
    if (data.dateofbirth !== null && data.dateofbirth) {
      data.dateofbirth = DateUtils.format(data.dateofbirth, 'yyyy-MM-dd')
    }
    setSubmitStatus(StatusMap.PENDING)
    const result = await ContactUsFormService.sendFeedback(data)
    setSubmitStatus(result.success ? StatusMap.DONE : StatusMap.ERROR)
  }

  const getFeedbackTypeFieldSetRender = (feedbackType: FeedbackValues) => {
    switch (feedbackType) {
      case FEEDBACK_TYPE_VALUES.GENERAL:
        return GeneralFieldSet
      case FEEDBACK_TYPE_VALUES.ONLINE:
        return OnlineFieldSet
      case FEEDBACK_TYPE_VALUES.BONUS_CARD:
        return BonusCardFieldSet
      case FEEDBACK_TYPE_VALUES.PRODUCT:
        return ProductFieldSet
      case FEEDBACK_TYPE_VALUES.IN_STORE_HOME_DELIVERY:
        return InStoreHomeDeliveryFieldSet
      case FEEDBACK_TYPE_VALUES.EMPTY:
      default:
        return null
    }
  }

  const feedbackTypeWatcher = form.watch('myquestion')

  const allowSubmit = feedbackTypeWatcher !== ''

  const FieldSetComponent = getFeedbackTypeFieldSetRender(feedbackTypeWatcher)

  return (
    <Box
      as="form"
      onSubmit={form.handleSubmit(handleSubmit)}
      paddingY="24px"
      paddingX="20px"
      position="relative"
    >
      <LoadingOverlay isLoading={submitStatus === StatusMap.PENDING} />
      <Stack bg="white" className={formFieldsContainer} width="full">
        {submitStatus === StatusMap.ERROR ? (
          <Alert status="error">
            <Text variant="text3">
              <Text variant="text3" color="error">
                Your message hasn&apos;t been sent. Please try again later.
              </Text>
            </Text>
          </Alert>
        ) : submitStatus === StatusMap.DONE ? (
          <Stack width="full">
            <Text variant="text3">Your message has been sent.</Text>
            <Text variant="text3">
              We will contact you shortly if your message requires a response.
            </Text>
          </Stack>
        ) : (
          <>
            <Text variant="text3">Please fill in the form...</Text>
            <Stack spacing="24px">
              <Controller
                name="myquestion"
                control={form.control}
                rules={{required: false}}
                render={(renderProps) => {
                  const {
                    field: {name, value},
                    fieldState: {isTouched, invalid},
                  } = renderProps
                  return (
                    <Select
                      validatedSuccessfully={isTouched && !invalid}
                      backgroundColor="gray25"
                      name={name}
                      value={value}
                      onChange={handleFeedbackTypeChange}
                    >
                      {feedbackTypeOptions?.map(({label, value}) => {
                        return (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        )
                      })}
                    </Select>
                  )
                }}
              />
              {FieldSetComponent ? <FieldSetComponent form={form} /> : null}
            </Stack>

            {allowSubmit && (
              <Stack marginTop="24px" spacing="20px">
                <Text variant="text3" color="textMuted" lineHeight="shorter">
                  *Required
                </Text>
                {recaptchaEnabled && 
                  <GoogleRecaptcha
                    onErrorCallback={() => {
                      setCaptchaValidationSuccess(false)
                    }}
                    onSuccessCallback={() => {
                      setCaptchaValidationSuccess(true)
                    }}
                    onExpireCallback={() => {
                      setCaptchaValidationSuccess(false)
                    }}
                  />
                }

                <Box>
                  <Button
                    type="submit"
                    variant="primary"
                    size="sm"
                    disabled={!captchaValidationSuccess}
                    display="inline-flex"
                  >
                    Send Feedback
                  </Button>
                </Box>
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Box>
  )
}
