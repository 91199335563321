import React, {RefObject} from 'react'
import {FieldValues, UseFormReturn} from 'react-hook-form'
import {ObjectValues} from './utils'
import {ContentBody} from 'dc-delivery-sdk-js/build/main/lib/content/model/ContentBody'
import {TopUpTotals, TopUpData} from './store/customer'
import {useDisclosure} from '../vanilla'
import {CREDENTIAL_SCREENS} from '../utils/constants'
import {PopoverProps} from 'react-tiny-popover'
import {BookADeliveryProps} from '../components/header/book-a-delivery-popover'
import {History} from 'history';
import { AutocompleteApi } from '@algolia/autocomplete-js'

export enum CheckoutSteps {
  'CONTACT_INFO',
  'SHIPPING_ADDRESS',
  'SHIPPING_METHOD',
  'PAYMENT',
  'CONFIRMATION',
}

type Content<SchemaType = Record<string, unknown>> = SchemaType & ContentBody

type SlotComponentProps<SchemaType, RestPropsType> = RestPropsType & {
  content: Content<SchemaType>
}

export type SlotComponent<
  ComponentTypeSchemaProps,
  RestComponentTypeProps = Record<string, unknown>,
> = (
  props: SlotComponentProps<ComponentTypeSchemaProps, RestComponentTypeProps>,
) => JSX.Element | null

export interface ErrorWithTextMessage {
  message: string
}

export type UseFields<Props = {}, FormValues extends FieldValues = {}> = Props & {
  form: UseFormReturn<FormValues>
}

export const CredentialModalPlacementMap = {BOTTOM: 'bottom', ATTACHED: 'attached'}

export type CredentialModalPlacement = ObjectValues<typeof CredentialModalPlacementMap>

export interface UITopupDetails extends Record<string, unknown> {
  success: boolean
  cardEnding: string
  paymentMethodName: string
  topUpTotals: TopUpTotals
  topUpData: TopUpData
  order: string
}

export interface UICheckoutOrderDetails {
  Order: {
    basketID: string
    customerNo: string
    deliverySlotId: string
    deliveryStoreId: string
    isTopUp: boolean
    orderNo: string
    shippingTotalPrice: number
    totalGrossPrice: number
  }
}

export type CredentialScreens = ObjectValues<typeof CREDENTIAL_SCREENS>

export interface CredentialModalContextValue extends ReturnType<typeof useDisclosure> {
  callback: () => void
  attachmentRef: RefObject<HTMLDivElement>
  modalPlacementType: CredentialModalPlacement
  defaultCredentialScreen: CredentialScreens
  showCredentialModal: (
    type: CredentialModalPlacement,
    callback?: () => void,
    defaultScreen?: CredentialScreens,
  ) => void
  setModalPlacementType: React.Dispatch<React.SetStateAction<string>>
  setCallback: (callbackFunction: () => void) => void
}

export type BonusCardMode = 'initial' | 'success' | 'error'
export interface BonusCardContextValue {
  mode: BonusCardMode
  setMode: React.Dispatch<React.SetStateAction<BonusCardMode>>
  toggle: ReturnType<typeof useDisclosure>['onToggle']
}

export interface CheckoutStateContextValue {
  billingAddressFormValid: boolean
  setBillingAddressFormValid: React.Dispatch<React.SetStateAction<boolean>>
  saveCreditCard: boolean
  setSaveCreditCard: React.Dispatch<React.SetStateAction<boolean>>
  saveNewAddress: boolean
  setSaveNewAddress: React.Dispatch<React.SetStateAction<boolean>>
}

export const BookDeliveryPopoverPlacementMap = {
  BOTTOM: 'bottom',
  ATTACHED: 'attached',
  CENTER: 'center',
} as const

export type BookDeliveryPopoverPlacement = ObjectValues<typeof BookDeliveryPopoverPlacementMap>

export type BookDeliveryStateArg = {
  clickedHitId?: string
  placement?: BookDeliveryPopoverPlacement
  bookDeliveryProps?: BookADeliveryProps
}

export interface PopoverComponentProps extends Omit<PopoverProps, 'isOpen' | 'content'> {
  isOpen?: boolean
  hitId?: string
  onPromoBadgeClick?: () => void
}

export interface BookDeliveryPopoverContextValue {
  isOpen: boolean
  clickedHitId: string | null
  popoverPlacementType: BookDeliveryPopoverPlacement
  setPlacement: React.Dispatch<React.SetStateAction<BookDeliveryPopoverPlacement>>
  showBookDeliveryPopover: (args?: BookDeliveryStateArg) => void
  toggleBookDeliveryPopover: (args?: BookDeliveryStateArg) => void
  hideBookDeliveryPopover: (args?: BookDeliveryStateArg) => void
  PopoverWrapperComponent: (props: PopoverComponentProps) => JSX.Element
}

export interface ModalNavigationProps {
  history: History<unknown>
  searchRef: React.MutableRefObject<AutocompleteApi<any> | undefined>
}

export enum NotificationType {
  success = 'success',
  warning = 'warning',
  error = 'error'
}

export interface Notification {
    type: NotificationType
    message: string
    autoClose?: boolean
    autoCloseTimeMs?: number
    isClosable?: boolean
    id?: string
    revealed?: boolean
}

export interface NotificationContextValue {
  notifications: Notification[]
  triggerNotification: (Notification: Notification) => void
  removeNotification: (id: number | undefined) => void
}
