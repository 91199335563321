export const REFINEMENTS_ID_MAP = {
  CATEGORY: 'categoryPageId',
  BRAND: 'brand',
  PRICE: 'priceRange',
  PACK_SIZE: 'filterPackSize',
  FILTER_BRAND: 'filterBrand',
  FILTER_TYPE: 'filterType',
  WEIGHT: 'filterWeight',
  LITRE: 'filterLitre',
  MANUFACTURE_SKU: 'manufacturerSKU',
  SERVING: 'filterServing',
  BUYING_SUB_GROUP_1: 'buyingSubGroup1',
  BUYING_SUB_GROUP_2: 'buyingSubGroup2',
  CHAMBER: 'chamber',
  BUYING_GROUP: 'buyingGroup',
  PRIMARY_CATEGORY_ID: 'primary_category_id'
}

/* Those hardcoded refinements are used on Search page.
 * As we can get refinements list for search page. */
export const SEARCH_DEFAULT_REFINEMENTS = {
  'CATEGORY': {
    label: 'Category',
    attribute: REFINEMENTS_ID_MAP.CATEGORY
  },
  'PRICE': {
    label: 'Price', 
    attribute: REFINEMENTS_ID_MAP.PRICE
  },
  'BRAND': {
      label: 'Brand',
      attribute: REFINEMENTS_ID_MAP.BRAND
  }
}
