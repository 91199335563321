import React, {PropsWithChildren, useMemo, useState} from 'react'
import {observer} from 'mobx-react'
import {useDisclosure} from '../../vanilla'
import {
  BookDeliveryPopoverContextValue,
  BookDeliveryPopoverPlacement,
  BookDeliveryPopoverPlacementMap,
  BookDeliveryStateArg,
  PopoverComponentProps,
} from '../../types/ui'
import {ArrowContainer, Popover} from 'react-tiny-popover'
import {Modal} from '../../components/modal'
import {BookADelivery, BookADeliveryProps} from '../../components/header/book-a-delivery-popover'
import {BookDeliveryPopoverProvider} from '../'

export const BookADeliveryPopoverContext = observer(({children}: PropsWithChildren<object>) => {
  const bookDeliveryPopoverOpenState = useDisclosure(false)

  const [popoverPlacementType, setPopoverPlacementType] = useState<BookDeliveryPopoverPlacement>(
    BookDeliveryPopoverPlacementMap.ATTACHED,
  )
  /** clickedHitId is used for the PLP page/search-input to show the popover for the clicked hit. **/
  const [clickedHitId, setClickedHitId] = useState<string | null>(null)

  /** bookDeliveryProps is used for the PLP page/search-input to pass the props to the BookADelivery components,
   * which can be rendered inside Popover component. **/
  const [bookDeliveryProps, setBookDeliveryProps] = useState<BookADeliveryProps>({})

  const bookDeliveryInModal = popoverPlacementType !== BookDeliveryPopoverPlacementMap.ATTACHED

  const setContextState = (state?: BookDeliveryStateArg) => {
    const {placement, clickedHitId, bookDeliveryProps} = state ?? {}
    const isMobile = window.innerWidth < 768

    const placementType =
      placement ??
      (isMobile ? BookDeliveryPopoverPlacementMap.BOTTOM : BookDeliveryPopoverPlacementMap.ATTACHED)

    setPopoverPlacementType(placementType)

    setClickedHitId(clickedHitId ?? null)

    setBookDeliveryProps(bookDeliveryProps ?? {})
  }

  const handleOpenBookDeliveryPopover = (state?: BookDeliveryStateArg) => {
    setContextState(state)
    bookDeliveryPopoverOpenState.onOpen()
  }

  const handleHideBookDeliveryPopover = (state?: BookDeliveryStateArg) => {
    setContextState(state)
    bookDeliveryPopoverOpenState.onClose()
  }

  const handleToggleBookDeliveryPopover = (state?: BookDeliveryStateArg) => {
    setContextState(state)
    bookDeliveryPopoverOpenState.onToggle()
  }

  const getPlacementCssRules = (placement: BookDeliveryPopoverPlacement) => {
    switch (placement) {
      case BookDeliveryPopoverPlacementMap.BOTTOM:
        return {
          bottom: 0,
        }
      case BookDeliveryPopoverPlacementMap.CENTER:
        return {
          top: '50%',
          transform: 'translateY(-50%)',
        }
    }
  }

  /** This component is used to wrap Delivery Actions/Add to cart buttons. **/
  const PopoverWrapperComponent = useMemo(
    () =>
      ({children, hitId, onPromoBadgeClick, isOpen = false, ...rest}: PopoverComponentProps) =>
        !bookDeliveryInModal ? (
          <Popover
            isOpen={(hitId === clickedHitId && bookDeliveryPopoverOpenState.isOpen) || isOpen}
            {...rest}
            content={({position, childRect, popoverRect}) => (
              <ArrowContainer
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor="white"
                data-test-selector="book-delivery-popover"
                arrowSize={10}
                arrowStyle={{zIndex: '101'}}
              >
                <BookADelivery
                  onPromoBadgeClick={onPromoBadgeClick}
                  setIsOpen={bookDeliveryPopoverOpenState.setIsOpen}
                  {...bookDeliveryProps}
                />
              </ArrowContainer>
            )}
          >
            {children}
          </Popover>
        ) : (
          <>{children}</>
        ),
    [bookDeliveryPopoverOpenState.isOpen, clickedHitId, bookDeliveryInModal, bookDeliveryProps],
  )

  const contextState: BookDeliveryPopoverContextValue = useMemo(
    () => ({
      isOpen: bookDeliveryPopoverOpenState.isOpen,
      clickedHitId,
      popoverPlacementType,
      PopoverWrapperComponent,
      showBookDeliveryPopover: handleOpenBookDeliveryPopover,
      hideBookDeliveryPopover: handleHideBookDeliveryPopover,
      toggleBookDeliveryPopover: handleToggleBookDeliveryPopover,
      setPlacement: setPopoverPlacementType,
    }),
    [bookDeliveryPopoverOpenState.isOpen, popoverPlacementType, PopoverWrapperComponent],
  )

  return (
    <BookDeliveryPopoverProvider value={contextState}>
      {children}
      {bookDeliveryInModal ? (
        <Modal
          size="md-search"
          closeIcon={false}
          dialogContentProps={{
            style: {
              position: 'absolute',
              ...getPlacementCssRules(popoverPlacementType),
              zIndex: '10000',
            },
          }}
          {...bookDeliveryPopoverOpenState}
        >
          <BookADelivery
            setIsOpen={bookDeliveryPopoverOpenState.setIsOpen}
            search={popoverPlacementType === BookDeliveryPopoverPlacementMap.BOTTOM}
            {...bookDeliveryProps}
          />
        </Modal>
      ) : null}
    </BookDeliveryPopoverProvider>
  )
})
