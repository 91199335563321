import React from "react"
import { NotificationItem } from "."
import { Stack } from "../../vanilla"
import { NotificationContainerStyle, NotificationHolderStyle } from "./styles.css"
import { useAlertsStore } from "../../store/hooks/useStore"
import { observer } from "mobx-react"

export const NotificationHolder = observer(() => {
    const alertsStore = useAlertsStore()

    return (
        <Stack className={NotificationContainerStyle}>
            <Stack className={NotificationHolderStyle}>
                {alertsStore?.notifications.map((item) => <NotificationItem {...item} />)}
            </Stack>
        </Stack>
    )
})