import React, {ChangeEvent, ElementType, useEffect, useMemo, useState} from 'react'
import cx from 'classnames'
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker'
import {datepickerStyles, calendarStyles, popperStyles} from './styles.css'
import {inputLabelRecipe, inputRecipe} from '../../vanilla/components/input.css'
import {Box, Flex, Text, Select, BoxProps} from '../../vanilla'
import {Sprinkles} from '../../vanilla/sprinkles.css'
import {CalendarIcon, ChevronLeftIcon, ChevronRightIcon} from '../icons'
import {BaseButton} from '../../vanilla'
import 'react-datepicker/dist/react-datepicker.css'

interface Datepicker extends ReactDatePickerProps {
  id?: string
  label: string
  width?: Sprinkles['width']
  error: boolean | null
  headerYears?: number[]
  headerDefaultSelectedYear?: number
  containerProps?: BoxProps<ElementType>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Datepicker = (props: Datepicker) => {
  const {
    error = null,
    label,
    selected,
    value,
    onFocus,
    onBlur,
    headerYears,
    headerDefaultSelectedYear,
    id,
    containerProps = {},
    ...rest
  } = props
  const [isFocused, setIsFocused] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const inputClassname = inputRecipe({
    error: error !== null,
  })

  const isFilled = !!selected || isFocused

  const classNameLabel = inputLabelRecipe({
    size: 'sm',
    isFilled,
  })

  return (
    <Box position="relative" display="flex" flex-direction="column" {...containerProps}>
      <ReactDatePicker
        id={id}
        dateFormat="dd/MM/yyyy"
        selected={selected}
        className={cx(inputClassname, datepickerStyles)}
        onFocus={(e) => {
          setIsFocused(true)
          onFocus?.(e)
        }}
        onBlur={(e) => {
          setIsFocused(false)
          onBlur?.(e)
        }}
        showPopperArrow={false}
        popperPlacement="bottom-start"
        popperModifiers={[
          {
            name: 'flip',
            options: {
              // Prevents flipping calendar to top
              fallbackPlacements: ['top-start'],
              allowedAutoPlacements: ['top-start'],
            },
          },
        ]}
        calendarClassName={calendarStyles}
        popperClassName={popperStyles}
        onCalendarOpen={() => setIsOpen(true)}
        onCalendarClose={() => setIsOpen(false)}
        disabledKeyboardNavigation
        renderCustomHeader={(state) => (
          <CalendarHeader
            {...state}
            years={headerYears || []}
            dateState={selected}
            defaultSelectedYear={headerDefaultSelectedYear}
          />
        )}
        {...rest}
      />
      <Box
        position="absolute"
        right="0px"
        top="0px"
        width="36px"
        height="36px"
        borderLeft="1px"
        borderColor={isOpen ? 'gray800' : 'gray200'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{pointerEvents: 'none'}}
      >
        <CalendarIcon style={{width: 14, height: 14}} color="gray800" />
      </Box>

      {label && (
        <Box as="label" htmlFor={id} aria-label={label} className={classNameLabel}>
          <Text
            variant="unstyled"
            as="span"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            fontWeight="inherit"
            fontSize="inherit"
            lineHeight="inherit"
            style={{height: 'inherit'}}
            paddingX={isFilled ? '4px' : '0px'}
            backgroundColor={isFilled ? 'white' : 'transparent'}
          >
            {label}
          </Text>
        </Box>
      )}
    </Box>
  )
}

const CalendarHeader = (
  props: ReactDatePickerCustomHeaderProps & {
    years: number[]
    defaultSelectedYear?: number
    dateState?: Date | null
  },
) => {
  const {
    increaseMonth,
    decreaseMonth,
    monthDate,
    changeYear,
    nextMonthButtonDisabled,
    prevMonthButtonDisabled,
    years,
    defaultSelectedYear,
    dateState,
  } = props

  const monthStr = monthDate.toLocaleDateString('en-GB', {
    month: 'long',
  })

  const selectedYear = monthDate.getFullYear()

  const selectYears = useMemo(() => {
    if (years && years.length) return years

    const start = 1930
    const end = new Date().getFullYear()

    return Array.from([...new Array(end - start + 1).keys()], (year) => year + start)
  }, [])

  useEffect(() => {
    if (!dateState && defaultSelectedYear) {
      changeYear(defaultSelectedYear)
    }
  }, [defaultSelectedYear])

  const selectValue = selectedYear

  return (
    <Flex justify="space-between" align="center">
      <BaseButton
        height="auto"
        variant="unstyled"
        size="icon"
        aria-label="Previous month"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <ChevronLeftIcon boxSize="16px" color="black" />
      </BaseButton>

      <Flex gap="8px" align="center">
        <Text variant="text3" color="gray800" lineHeight="shorter">
          {monthStr}
        </Text>
        <Select
          id="datepicker-years-select"
          validatedSuccessfully={false}
          value={selectValue}
          error={false}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => changeYear(+e.target.value)}
          style={{minWidth: 85}}
        >
          {selectYears.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </Flex>
      <BaseButton
        height="auto"
        variant="unstyled"
        size="icon"
        aria-label="Next month"
        disabled={nextMonthButtonDisabled}
        onClick={increaseMonth}
      >
        <ChevronRightIcon boxSize="16px" color="black" />
      </BaseButton>
    </Flex>
  )
}
