import {nanoid} from 'nanoid'
import {encode as base64encode} from 'base64-arraybuffer'
import { EncodeType } from './types/utils'

// Globals
const isServer = typeof window === 'undefined'

/**
 * Creates Code Verifier use for PKCE auth flow.
 */
export const createCodeVerifier = async () => {
  if (isServer) {
    //@ts-ignore
    const randomstring = await import('randomstring').then((module) => {
      return module.default.generate(128)
    })
    return randomstring
  }

  return nanoid(128)
}

const hexEncode = (data: ArrayBuffer) => {
  const hashArray = new Uint8Array(data)
  return Array.from(hashArray, byte => byte.toString(16).padStart(2, '0')).join('')
}

/**
 * Creates Code Challenge based on Code Verifier
 */
export const generateCodeChallenge = async (codeVerifier: string, encodeType: EncodeType) => {
  let encodedValue

  if (isServer) {
    await import('crypto').then((module) => {
      //@ts-ignore
      const crypto = module.default
      encodedValue = crypto.createHash('sha256').update(codeVerifier).digest(encodeType)
    })
  } else {
    const encoder = new TextEncoder()
    const data = encoder.encode(codeVerifier)
    const digest = await window.crypto.subtle.digest('SHA-256', data)
    
    switch (encodeType) {
      case EncodeType.HEX:
        encodedValue = hexEncode(digest)
        break;
      case EncodeType.BASE64:
      default:
        encodedValue = base64encode(digest)
        break;
    }
  }

  if (encodeType === EncodeType.BASE64) {
    encodedValue = encodedValue.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
  }

  return encodedValue
}
