import React from 'react'
import {ColorPicker} from '../../types/cms'
import {BoxProps, Stack, Text, TextProps} from '../../vanilla'
import {Markdown, MarkdownProps} from './Markdown'
import {SlotComponent} from '../../types/ui'

interface CalloutMessageCmsContent {
  text: {
    richText: string
  }
  spacing: string
  isTextWhite: boolean
  heading: string
  backgroundColor?: ColorPicker
}

interface CalloutMessageComponentProps {
  markdownComponentProps?: MarkdownProps['componentProps']
  containerProps?: BoxProps<React.ElementType>
  headingProps?: TextProps<React.ElementType>
}

export const CalloutMessage: SlotComponent<
  CalloutMessageCmsContent,
  CalloutMessageComponentProps
> = ({content, markdownComponentProps, containerProps, headingProps}) => {
  if (!content) {
    return null
  }

  const {isTextWhite, text, spacing, heading, backgroundColor} = content

  const padding = ({
    None: '0px',
    Small: '12px',
    Medium: '24px',
    Large: '36px',
  }[spacing] || '0px') as '0px' | '12px' | '24px' | '36px'

  return (
    <Stack
      padding={padding}
      spacing="24px"
      style={{backgroundColor: backgroundColor?.color || 'transparent'}}
      {...(containerProps || {})}
    >
      {heading && (
        <Text variant="heading1" color={isTextWhite ? 'white' : 'black'} {...(headingProps || {})}>
          {heading}
        </Text>
      )}
      <Markdown
        markdown={(text?.richText || '') as string}
        componentProps={{
          p: {
            variant: 'text3',
            lineHeight: 'short',
            color: isTextWhite ? 'white' : 'black',
            ...(markdownComponentProps?.p || {}),
          },
          a: {
            variant: 'text3',
            textDecoration: 'underline',
            lineHeight: 'short',
          }
        }}
      />
    </Stack>
  )
}
