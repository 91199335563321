export enum HttpMethods {
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  GET = 'GET',
  DELETE = 'DELETE',
}

/**
 * Type for getting any possible HTTP methods from enum
 */
export type HttpMethodsValues = `${HttpMethods}`

export type TokenBody = {
  grantType: string
  code: string
  usid: string
  codeVerifier: string
  redirectUri: string
}

export type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : Lowercase<S>

export type KeysToCamelCase<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends {} ? KeysToCamelCase<T[K]> : T[K]
}

export interface LegacyWindow {
  webkitOfflineAudioContext: {
    prototype: OfflineAudioContext;
    new(contextOptions: OfflineAudioContextOptions): OfflineAudioContext;
    new(numberOfChannels: number, length: number, sampleRate: number): OfflineAudioContext;
  }
}

export enum EncodeType {
  BASE64 = 'base64',
  HEX = 'hex'
}