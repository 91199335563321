import JSCookies, {CookieAttributes} from 'js-cookie'

interface BaseStorage {
  set(name: string, value: string, options?: any): void
  get(name: string): string | null | undefined
  remove(name: string): void
  clear(): void
}

class CookieStorage implements BaseStorage {
  available: boolean

  constructor() {
    this.available = false

    if (typeof document === 'undefined') {
      console.warn('Cookie is not available on current environment')
      return
    }

    this.available = true
  }

  set(name: string, value: string, options: CookieAttributes) {
    if (!this.available) return

    JSCookies.set(name, value, {secure: true, ...options})
  }

  get(name: string) {
    if (!this.available) return
    return JSCookies.get(name)
  }

  remove(name: string) {
    if (!this.available) return
    JSCookies.remove(name)
  }

  clear() {
    if (!this.available) return
    document.cookie.split(";").forEach(cookie => {
        const cookieName = cookie.split("=")[0].trim()
        document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`
    })
  }
}

export const Cookies = new CookieStorage()

class WindowLocalStorage implements BaseStorage {
  available: boolean

  constructor() {
    this.available = false

    if (typeof window === 'undefined') {
      console.warn('LocalStorage is not available on the current environment.')
      return
    }
    this.available = true
  }

  set(key: string, value: string) {
    if (!this.available) return

    window.localStorage.setItem(key, value)
  }

  get(key: string) {
    if (!this.available) return

    return window.localStorage.getItem(key)
  }

  remove(key: string) {
    if (!this.available) return

    window.localStorage.removeItem(key)
  }

  clear() {
    if (!this.available) return
    Object.keys(window.localStorage).forEach(storageKey => {
        window.localStorage.removeItem(storageKey)
    })
  }
}

export const LocalStorage = new WindowLocalStorage()

class WindowSessionStorage implements BaseStorage {
  available: boolean

  constructor() {
    this.available = false

    if (typeof window === 'undefined') {
      console.warn('SessionStorage is not available on the current environment.')
      return
    }
    this.available = true
  }

  set(key: string, value: string) {
    if (!this.available) return

    window.sessionStorage.setItem(key, value)
  }

  get(key: string) {
    if (!this.available) return

    return window.sessionStorage.getItem(key)
  }

  remove(key: string) {
    if (!this.available) return 

    window.sessionStorage.removeItem(key)
  }

  clear() {
    if (!this.available) return
    Object.keys(window.sessionStorage).forEach(storageKey => {
      window.sessionStorage.removeItem(storageKey)
    })
  }
}

export const SessionStorage = new WindowSessionStorage()
