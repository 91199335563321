import {Cookies, LocalStorage} from '../utils/storages'
import {LOCALSTORAGE_KEYS} from '../utils/constants'
import {useBasketStore, useCustomerStore} from '../store/hooks/useStore'

export const useSoftLogoutData = () => {
  const {clearSoftLogoutBasket} = useBasketStore()
  const {clearSoftLogoutWishlist, isSoftLoggedIn} = useCustomerStore()

  const details = LocalStorage.available ? JSON.parse(
    LocalStorage.get(LOCALSTORAGE_KEYS.SOFT_LOGOUT_ACCOUNT_DETAILS) || '{}',
  ) : {}

  const postcode = LocalStorage.available ? LocalStorage.get(LOCALSTORAGE_KEYS.CUSTOMER_POSTALCODE) ?? null : null

  const signOutEntirely = async () => {
    await clearSoftLogoutBasket()
    await clearSoftLogoutWishlist()
    LocalStorage.remove(LOCALSTORAGE_KEYS.SOFT_LOGOUT_ACCOUNT_DETAILS)
  }

  return {
    email: details?.login,
    phone: details?.phone,
    firstName: details?.firstName,
    softLogout: isSoftLoggedIn(),
    customerNo: details?.customerNo,
    lastName: details?.lastName,
    addressBook: details?.addressBook,
    orderCount: details?.orderCount,
    legacyOrderCount: details?.legacyOrderCount,
    postcode,
    signOutEntirely,
  }
}
