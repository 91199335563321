import {baseThemeContract} from '../../../vanilla/vars.css'
import {ArrowContainer, Popover, PopoverPosition} from 'react-tiny-popover'
import {Box, Flex, Stack, Text} from '../../../vanilla'
import {CheckIcon, InfoIcon, CloseIcon, VanMotionIcon, StarMotionIcon} from '../../icons'
import React, {ReactNode, useState, useEffect} from 'react'
import {ProgressBar} from '../../progress-bar'
import {Link} from '../../link'
import { BasketPromotionsThreshold } from '../../../types/store/basket'
import { handleClosePopoverEvents, isMobile } from '../../../utils/utils'

interface PromoThresholdProgressBarProps {
  promoThreshold: BasketPromotionsThreshold,
  currency: string
}

export const PromoThresholdProgressBar = ({
  promoThreshold,
  currency,
}: PromoThresholdProgressBarProps) => {
  const [popoverTextOpen, setPopoverTextOpen] = useState(false)
  const [popoverBarOpen, setPopoverBarOpen] = useState(false)

  const openTextPopover = () => {
    setPopoverTextOpen(true)
    setPopoverBarOpen(false)
  }

  return (
    <>
      {promoThreshold.enabled && (
        <Flex alignSelf="center" align="center" gap="8px">
          <Text as="span" variant="text3">
            {promoThreshold.complete ? promoThreshold.completedText : promoThreshold.progressText}
          </Text>
          <PromoThresholdPopover
            onClose={() => setPopoverTextOpen(false)}
            promoThreshold={promoThreshold}
            isOpen={popoverTextOpen}
            positions="top"
          >
            <InfoIcon className='promo-threshold-popover' boxSize="16px" onClick={() => openTextPopover()} />
          </PromoThresholdPopover>
        </Flex>
      )}
      <PromoThresholdPopover
        onClose={() => setPopoverBarOpen(false)}
        promoThreshold={promoThreshold}
        isOpen={popoverBarOpen}
        positions="bottom"
      >
        <Box width="full" onClick={() => setPopoverBarOpen((prev) => !prev)}>
          <ProgressBar
            color="promotionColor"
            reachedColor="accent1"
            progressValue={Number(promoThreshold.progress)}
            icon={promoThreshold.svg == 'van-motion' ? VanMotionIcon : StarMotionIcon}
            reachedContent={
              <Flex as="span" gap="4px" align="center" position="absolute" style={{left: 5}}>
                <CheckIcon color="white" boxSize="12px" />
                <Text variant="text1" color="white">
                  {promoThreshold.completedText}
                </Text>
              </Flex>
            }
          />
        </Box>
      </PromoThresholdPopover>
    </>
  )
}

interface PromoThresholdPopoverProps {
    promoThreshold: BasketPromotionsThreshold
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  positions: PopoverPosition
}

const PromoThresholdPopover = ({promoThreshold, isOpen, onClose, positions, children}: PromoThresholdPopoverProps) => {
  useEffect(() => {
    handleClosePopoverEvents(isOpen, onClose, 'promo-threshold-popover')
  }, [isOpen, onClose]);

  return (
    <Popover
      positions={[positions]}
      align="center"
      reposition={true}
      containerStyle={{zIndex: baseThemeContract.zIndex.popover}}
      onClickOutside={onClose}
      isOpen={isOpen}
      content={({position, childRect, popoverRect}) => (
        <Stack>
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="white"
            arrowSize={10}
            arrowStyle={{zIndex: baseThemeContract.zIndex.popover}}
          >
            <Stack
              spacing="16px"
              paddingX="20px"
              paddingTop="20px"
              paddingBottom="32px"
              bg="white"
              style={{
                maxWidth: isMobile() ? "100vw" : 420,
                width: isMobile() ? "calc(100vw - 20px)" : "auto",
                boxShadow: "0 10px 25px 0 rgb(0 0 0 / 40%)",
              }}
              className='promo-threshold-popover'
            >
              <Flex align="center" justify="space-between" gap="4px">
                <Text variant="heading0">{promoThreshold.popoverTitle}</Text>
                <CloseIcon marginLeft="8px" boxSize="30px" onClick={onClose} />
              </Flex>
              <Text variant="text3" lineHeight="short">
                {promoThreshold.popoverText}
              </Text>
              <Text
                variant="text3"
                to={promoThreshold.popoverLink}
                as={Link}
                color="accent3"
                textDecoration="underline"
              >
                {promoThreshold.popoverLinkText}
              </Text>
            </Stack>
          </ArrowContainer>
        </Stack>
      )}
    >
      {/* @ts-ignore*/}
      {children}
    </Popover>
  )
}
