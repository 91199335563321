import "app/components/header/styles.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/components/header/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81Wy47jIBC85yuQ9hIfiBLnMYlz2T8ZYSAxCgYL47Ezq/z7Ykiy4DgPa2akPVnC1d3V3dUNk/fZIeYHEU+TTH5QBf6MAMCSS5WAD6TGEL7P1vXbKq1jEm3NP00bDQnFUiHNpEhAJQhVnAm6HZ1Gk4u7WSKkHic7pkoNccY4iazrVCoDh5zuzLELoxUSZYEUFTpwEVsD+5e5UIhzEE+nedkS+YTMRG4SMAus5i6DSpWt70Iyoalq8SnCh72Shi7sz+8tChwt+h35CK9kQ70vrdVOCg1ryvaZ7hrM69BgdS+v/nC0Y/293X2zjggrC46OCdhx2rTW7RcSpih2xkrW7THibC8g0zQvE4DphXiBCGFib7XQpbOPfISWRReQBoBUai3zPoxPeki7lqHt+kvVX3uRH7X8GL0k1DO3jKJ2lHBG8UFWGqaVKYJwoRLTG5RySh50fH4n2q8lxot0E2SwsX5QUVBk5hHTBAjZagKAqjQcSspNz/8d1pnpNjRD7ZC1QsX20Sz3FNbWqWSf9KZKIto+qWNlEa1qYdaLWJxzd8tIIcKq8gZTRPcb3LzWKT/Mjf/c59Bvv/YhTuRudV4oT4vGQ5g5efTbDZo981v7dDXcE0pPTQK/fRq8igsAaeTB9LG7wNG3SO26m5iwOrisqCESPG8XQ/Bcx/9Fk7etCO7QR1vpq2r0GzX0+nviftN1H+jnqpbpZLXsvYYH3K3HqPNmGGz7O6eEIVBiRakASBAwzpmANSM6awUTL4rGvXi8hOyTiKPwRdSdb9VG7U5wMOJ3EJfLUDnaXcvw+PSE2fVf+qMkZ3dY9orzpWV5Gp3+AkQZIuXUCgAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var checkoutButton = '_1k2lkn29';
export var linkUtilityMenu = '_1k2lkn20';
export var megaMenuInternalItem = '_1k2lkn24';
export var megaMenuInternalItemActive = '_1k2lkn23';
export var megaMenuInternalItemText = '_1k2lkn25';
export var megaMenuListItem = '_1k2lkn21';
export var megaMenuSection = '_1k2lkn22';
export var mobileNavigationMenuBackLink = '_1k2lkn27';
export var mobileNavigationMenuListItem = '_1k2lkn28';
export var mobileNavigationMenuListItemRoot = '_1k2lkn26';
export var viewBasketButton = '_1k2lkn2a';
export var viewBasketContent = '_1k2lkn2b';