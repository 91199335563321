import React, {CSSProperties} from 'react'
import {SlotComponent} from '../../types/ui'
import {Stack, Text} from '../../vanilla'
import {CmsImage} from './cms-image'
import {CTAButton} from '../../vanilla/components/CTAButton'
import {useSelectPromotionTracking} from '../../analytics/promotion-tracking'
import {ChevronRightIcon} from '../icons'
import {isMobile} from '../../utils/utils'
import { OfferCardCmsContent } from '../../types/cms'

export const OfferCard: SlotComponent<
  OfferCardCmsContent,
  {styles?: CSSProperties; size?: number, index?: number, isOfferCards?: boolean, offerCardsSlotGroupName?: string}
> = ({content, styles, size, index, isOfferCards, offerCardsSlotGroupName}) => {
  const {image, imageLink, title, cta, slotGroupName} = content

  const isOfferCardsList = isOfferCards ? isOfferCards : false;

  const promotion = {
    creative_name: image.images[0].data?.altText ?? `Offer card #${isOfferCardsList ? index : 1} missing alt text`,
    creative_slot: `${slotGroupName ?? offerCardsSlotGroupName} + ${content._meta.name}` ?? `Offer Card #${isOfferCardsList ? index : 1}`,
    promotion_id: imageLink ?? `Offer card #${isOfferCardsList ? index : 1} without link`,
    promotion_name: cta?.ctaText ?? `Offer card #${isOfferCardsList ? index : 1} without cta`,
    link_position: index ?? 1
  }

  const sendSelectPromotion = useSelectPromotionTracking()
  const isMobileFlag: boolean = isMobile()

  return (
    <Stack gap="0px" style={styles}>
      <CmsImage
        queryParams={[
          {w: 1752, fmt: 'auto', qlt: 'default'}, 
          {w: 3504, fmt: 'auto', qlt: 'default'}
        ]}
        images={image.images}
        width="full"
        height="auto"
        imageLink={imageLink}
        linkProps={{display: 'block', style: {fontSize: 0}}}
        onClickCapture={() => {
          sendSelectPromotion(promotion)
        }}
      />
      {title || cta?.ctaText ? (
        <Stack
          direction={'row'}
          paddingY={['16px', '20px', '32px']}
          paddingX={['16px', '40px', '24px']}
          backgroundColor="white"
          alignItems="center"
          gap="8px"
          flexGrow="1"
        >
          {title && (
            <Text
              flexGrow="1"
              variant="heading1"
              textAlign={['center', 'left', 'left']}
              lineHeight={['short', 'tall']}
            >
              {title}
            </Text>
          )}
          {isMobileFlag ?
            <ChevronRightIcon
              boxSize="12px"
              onClickCapture={() => {
                sendSelectPromotion(promotion)
              }}
              /> : cta?.ctaText && (
              <CTAButton
                cta={cta}
                onClickCapture={() => {
                  sendSelectPromotion(promotion)
                }}
              />
            )}
        </Stack>
      ) : null}
    </Stack>
  )
}
