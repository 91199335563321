import React, {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import classNames from 'classnames'
import {observer} from 'mobx-react-lite'
import {FormattedNumber} from 'react-intl'
import {DialogInner, unstable_DialogWrapper as DialogWrapper} from '@reach/dialog'
import {Link} from '../../link'
import {Box, Flex, Stack, Text, useDisclosure} from '../../../vanilla'
import {ArrowLeftIcon, BasketIcon, CloseIcon} from '../../icons'
import {useOverlayTransition} from '../../../vanilla/hooks/useOverlayTransition'
import {useBasketStore, useGlobalStore} from '../../../store/hooks/useStore'
import {DrawerTotals} from './partials/drawer-totals'
import {DrawerCartItems} from './partials/drawer-cart-items'
import {DeliverySection} from './partials/delivery-section'
import {cartDrawerDialog, cartDrawerDialogOpened, cartDrawerSizeBox} from './styles.css'
import {useItemsUnavailable} from '../../../store/hooks/useItemsUnavailable'
import { isMobile } from '../../../utils/utils'

const TRANSITION_DURATION = 400

interface CartDrawerProps extends ReturnType<typeof useDisclosure> {
  positionRef: React.RefObject<Element>
}

export const CartDrawer = observer((props: CartDrawerProps) => {
  const {isOpen, positionRef, onOpen, onClose} = props

  const location = useLocation()
  const basketStore = useBasketStore()
  const globalStore = useGlobalStore()

  const {isVisible, isReady} = useOverlayTransition({
    isOpen,
    duration: TRANSITION_DURATION,
  })

  const contentRef = useRef<HTMLDivElement | null>(null)

  // Opening drawer once user added product after cart being empty
  useEffect(() => {
    if (basketStore.openMiniBasketUI && !isOpen) {
      onOpen()
    }
  }, [basketStore.openMiniBasketUI])

  useEffect(() => {
    if (isOpen) {
      basketStore.setOpenMiniBasketUI(false)
      onClose()
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname === '/book-delivery') {
      setTimeout(() => {
        onClose()
      }, 3000)
    }
  }, [])

  const lockBodyScroll = () => {
    const scrollY = window.scrollY
    document.body.dataset.scrollY = `${scrollY}`
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollY}px`
    document.body.style.width = '100%'
  }
  
  const unlockBodyScroll = () => {
    const scrollY = document.body.dataset.scrollY
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.width = ''
    if (scrollY) {
      window.scrollTo(0, parseInt(scrollY))
      delete document.body.dataset.scrollY 
    }
  }
  
  useEffect(() => {
    if (isOpen && isMobile()) {
      lockBodyScroll()
    } else {
      unlockBodyScroll()
    }

    return () => {
      unlockBodyScroll()
    }
  }, [isOpen])

  useEffect(() => {
    if (!isOpen || !contentRef?.current) {
      return
    }

    // On click outside of CartDrawer
    function handleClick(event: MouseEvent) {
      if (contentRef.current && event.target instanceof Node && !contentRef.current.contains(event.target)) {
        basketStore.setOpenMiniBasketUI(false)
        onClose()
       }
    }

    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        basketStore.setOpenMiniBasketUI(false)
        onClose()
      }
    }

    document.addEventListener('click', (event) => handleClick(event))
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('click', (event) => handleClick(event))
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, contentRef?.current, onClose])

  const someItemsUnavailable = useItemsUnavailable()

  if (!isReady) {
    return null
  }

  return (
    <DialogWrapper isOpen={isVisible} containerRef={positionRef}>
      <DialogInner dangerouslyBypassFocusLock dangerouslyBypassScrollLock>
        <Box className={cartDrawerSizeBox}>
          <Box
            data-test-selector="cart-drawer"
            aria-label="Cart Drawer"
            height="full"
            position="fixed"
            top="0px"
            right="0px"
            zIndex="overlay"
            className={classNames(cartDrawerSizeBox, cartDrawerDialog, {
              [cartDrawerDialogOpened]: isVisible,
            })}
            style={{
              backgroundColor: 'white',
              boxShadow: '2px 0 10px 0 rgb(0 0 0 / 20%)',
              padding: 0,
              margin: 0,
              transition: `transform ${TRANSITION_DURATION}ms ease-out`,
            }}
            ref={contentRef}
          >
            <Stack spacing="0px" height="full">
              <Flex
                bg="white"
                align="center"
                justify="space-between"
                minHeight="60px"
                paddingX="16px"
                borderBottom="1px"
                borderColor="gray200"
              >
                <Flex
                  align="center"
                  onClick={() => {
                    basketStore.setOpenMiniBasketUI(false)
                    onClose()
                  }} 
                  display={['none', 'flex']}
                  cursor="pointer"
                >
                  <CloseIcon color="accent3" boxSize="20px" style={{marginLeft: '-1px'}} />
                  <Text as="span" variant="text3" color="accent3" lineHeight="none">
                    Close
                  </Text>
                </Flex>    
                <ArrowLeftIcon
                  cursor="pointer"
                  color="gray800"
                  display={['inline-flex', 'none']}
                  boxSize="20px"
                  onClick={() => {
                    basketStore.setOpenMiniBasketUI(false)
                    onClose()
                  }}
                />
                <Text
                  as="span"
                  variant="text3"
                  color="black"
                  lineHeight="none"
                  display={['inline-flex', 'none']}
                >
                  {basketStore.basket ? (<FormattedNumber
                    value={basketStore.basket?.productTotal || 0}
                    style="currency"
                    currency={globalStore.currency}
                  />) : (<Text>£0.00</Text>)}
                </Text>
                <Flex align="flex-end" gap="2px" as={Link} to="/basket">
                  <BasketIcon color="accent3" boxSize="20px" />
                  <Text as="span" variant="text3" color="accent3" lineHeight="none">
                    {basketStore.count || 0}
                  </Text>
                </Flex>
              </Flex>
              <DeliverySection />
              {someItemsUnavailable ? (
                <Box style={{backgroundColor: '#fbf7f8'}} padding="16px" margin="4px">
                  <Text color="accent0">
                    Some items in your basket are unavailable. These will be automatically removed
                    when you checkout.
                  </Text>
                </Box>
              ) : null}
                <DrawerCartItems />
              <DrawerTotals />
            </Stack>
          </Box>
        </Box>
      </DialogInner>
    </DialogWrapper>
  )
})
