import {isValid, differenceInDays, differenceInHours} from 'date-fns'
import {formatInTimeZone, utcToZonedTime} from 'date-fns-tz'

export const ICELAND_TIMEZONE = 'Europe/London'

// eslint-disable-next-line @typescript-eslint/prefer-namespace-keyword, @typescript-eslint/no-namespace
export module DateUtils {
  export function validateDate(date: Date) {
    return isValid(date)
  }

  /* Format date with current user time zone to Iceland timezone with provided date template */
  export function format(date: Date | string, formatTemplate: string) {
    if (!date || (typeof date === 'object' && !DateUtils.validateDate(date))) return ''

    return formatInTimeZone(date, ICELAND_TIMEZONE, formatTemplate)
  }

  /** Get current date in Iceland timezone.
   * Result is Date object in user's timezone with shifter hours to Iceland timezone
   */
  export function current() {
    return utcToZonedTime(new Date().toISOString(), ICELAND_TIMEZONE)
  }

  /** Transforms utc date string to date object in Iceland timezone.
   * Result is Date object in user's timezone with shifter hours to Iceland timezone
   */
  export function utcToZonedDate(utcString: string) {
    return utcToZonedTime(utcString, ICELAND_TIMEZONE)
  }

  export function formatDeliveryDate(startTime: string, endTime: string, dateFormat?: string) {
    if (!startTime || !endTime) return null

    const format = dateFormat || 'dd MMMM yyyy'

    const timeStr = `${DateUtils.format(startTime, 'HH:mm')} - ${DateUtils.format(
      endTime,
      'HH:mm',
    )}`
    const dateStr = DateUtils.format(endTime, format)

    return [timeStr, dateStr]
  }

  /**
   * Tests if a date is before another date
   * @param {Date} firstDate the date we're checking
   * @param {Date} secondDate the date to compare to
   * @returns {boolean} True if first date before second date
   */
  export function isBefore(firstDate: Date, secondDate: Date): boolean | null {
    // Return Null if invalid or not set dates
    if(!firstDate || !DateUtils.validateDate(firstDate) || !secondDate || !DateUtils.validateDate(secondDate)) {
      return null
    } 

    return firstDate.getTime() < secondDate.getTime()
  }

  export function getOrdinal(day:number){
    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
      case 1: return `${day}st`;
      case 2: return `${day}nd`;
      case 3: return `${day}rd`;
      default: return `${day}th`;
    }
  }

  /**
   * Get 12 hour time format string from date object eg 5:00PM
   * @param {Date} Standard JS date object
   * @returns {string} 12 time string
   */
  export function get12HourTime(date:Date){
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).format(date).replace(' ', '');
  }

  export const hoursSinceDate = (date: string | undefined) => {
    if (!date) return null
  
    const dateObj = new Date(date)
    const now = new Date()
    
    const diffInHours = differenceInHours(now, dateObj)
  
    return diffInHours
  }

  export const daysSinceDate = (date: string | undefined) => {
    if (!date) return null
  
    const dateObj = new Date(date)
    const today = new Date()
    
    const diffInDays = differenceInDays(today, dateObj)
  
    return Object.is(diffInDays, -0) ? 0 : diffInDays
  }


}
