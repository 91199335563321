import React, { useEffect, useState } from "react"
import { NotificationReveal, NotificationFadeOut, NotificationIconStyle, NotificationIconFadeIn, NotificationStyle, NotificationFadeIn } from "./styles.css"
import { Notification, NotificationType } from "../../types/ui"
import { CheckCircleIcon, CloseIcon, ExclamationCircleIcon } from "../icons"
import { Flex, Text } from "../../vanilla"
import { useAlertsStore } from "../../store/hooks/useStore"
import { observer } from "mobx-react"
import { useLocation } from "react-router-dom"

const getNotificationIcon = (type: NotificationType, revealed = false) => {
    let IconComponent
    const animation = !revealed ? NotificationIconFadeIn : ''
    const sharedClasses = `${NotificationIconStyle} ${animation}`
    switch (type) {
        case NotificationType.error:
            IconComponent = <ExclamationCircleIcon className={sharedClasses} color={'error'} boxSize={'30px'} />
            break;
        case NotificationType.success:
            IconComponent = <CheckCircleIcon className={sharedClasses} color={'green'} boxSize={'30px'} />
            break;
        case NotificationType.warning:
            IconComponent = <ExclamationCircleIcon className={sharedClasses} color={'warning'} boxSize={'30px'} />
            break;
        default:
            break;
    }
    return IconComponent
}

export const NotificationItem = observer((props: Notification) => {
    const alertsStore = useAlertsStore()
    const { pathname } = useLocation()
    const [isRemoving, setIsRemoving] = useState('')
    const { id, type, message, isClosable, revealed, autoClose, autoCloseTimeMs } = props
    const isAllowedPage = !pathname.includes('/checkout') && !pathname.includes('/login') && !pathname.includes('/register')

    const notificationFadeOut = (notiID = '') => {
        setIsRemoving(notiID)
        setTimeout(() => {
            alertsStore?.removeNotification(notiID)
        }, 500);
    }

    useEffect(() => {
        if (autoClose) {
            setTimeout(() => { notificationFadeOut(id) }, autoCloseTimeMs ?? 3000)
        }
    })

    const NotificationIcon = getNotificationIcon(type, revealed)
    return (
        <>
        {isAllowedPage &&             
            <Flex className={`${NotificationStyle} ${!revealed ? NotificationReveal : ''}  ${isRemoving === id ? NotificationFadeOut : ''} `} alignItems='center'>
                {NotificationIcon}
                <Flex className={`${!revealed ? NotificationFadeIn : ''}`}>
                    <Text>{message}</Text>
                    {isClosable && <CloseIcon onClick={() => notificationFadeOut(id)} boxSize='24px' cursor='pointer' />}
                </Flex>
            </Flex>
        }
        </>

    )
})