import { StoreModel } from "./utils"
import {CommerceAPI} from 'commerce-api'
import { Status, StatusMap } from "../types/commerce"
import { RootStore } from "./RootStore"
import { 
  makeObservable,
  observable,
  action,
  flow, 
} from "mobx"
import { formatSelfServeRefundFormData } from "../utils/self-serve-utils"
import { RefundData, RefundFormData, OrderStateForSelfServe } from "../types/self-serve"
import { getAppOrigin } from "@salesforce/pwa-kit-react-sdk/utils/url"
import { OrderState } from "./hooks/useOrder"
import { ORDER_UI_STATUS } from "../utils/constants"
import { OrderUIStatus } from "../types/store/order"


export class RefundStore implements StoreModel {
  api: CommerceAPI
  currentOrderForSelfServe: string
  status: Status
  rootStore: RootStore
  refundReasonCodes: string[]
  refundFormData: RefundFormData

  constructor(rootStore: RootStore, initialState = {status: StatusMap.IDLE}) {
    this.currentOrderForSelfServe = ''
    this.refundFormData = {}
    this.refundReasonCodes = []
    this.status = initialState?.status

    makeObservable(this, {
      clearRefundForm: action.bound,
      currentOrderForSelfServe: observable,
      exceededNumberOfDaysAfterDeliveryForRefund: action.bound,
      getRefundReasonCodes: flow.bound,
      isOrderAvailableForSelfServeRefund: action.bound,
      metMinimumNumberOfHoursAfterDeliverySlotForRefund: action.bound,
      refundFormData: observable,
      refundReasonCodes: observable,
      saveRefundForm: action.bound,
      submitRefund: flow.bound,
      status: observable,
    })

    this.api = rootStore.api
    this.rootStore = rootStore
  }

  get asJson() {
    return {
      status: this.status,
      refundReasonCodes: this.refundReasonCodes
    }
  }

  get isSelfServeRefundEnabled() {
    return this.rootStore.globalStore.customSitePreferences?.selfServeEnabled as boolean
  }

  get maxDaysAfterDeliveryForRefund() {
    return this.rootStore.globalStore.customSitePreferences?.selfServeNumberOfDaysAfterDelivery as number
  }

  get minHoursAfterOrderPlacedforRefund() {
    return this.rootStore.globalStore.customSitePreferences?.selfServeNumberOfHoursAfterDeliverySlot as number
  }

  get minMinutesAfterLastRefundRequest() {
    return this.rootStore.globalStore.customSitePreferences?.selfServeNumberOfMinutesAfterLastRequest as number
  }

   // Clear the form data from the store
  clearRefundForm() {
      this.refundFormData = {}
      this.currentOrderForSelfServe = ''
  }

  enoughTimeSinceLastRefundRequest(lastRefundRequestTime: string) {
    const minMinsBetweenRefundRequests = this.minMinutesAfterLastRefundRequest
    if (!minMinsBetweenRefundRequests) return false
    if (!lastRefundRequestTime) return true

    const minMsBetweenRefundRequests = minMinsBetweenRefundRequests * 60000
    const currentTime = new Date().getTime()
    const lastRefundRequest = new Date(lastRefundRequestTime).getTime()
    const timeSinceLastRefundRequest = currentTime - lastRefundRequest

    return timeSinceLastRefundRequest >= minMsBetweenRefundRequests
  }

  // Compare custom pref for days between order delivery date and refund request
  exceededNumberOfDaysAfterDeliveryForRefund(numberOfDays: number) {
    const maxDaysCustomPref = this.maxDaysAfterDeliveryForRefund
    if (!maxDaysCustomPref) return false
    return numberOfDays > maxDaysCustomPref
  }

  orderStatusIsSuitableForSelfServe(orderStatus: OrderUIStatus) {
    // Only allow completed, delivered & partially refunded orders to process self serve requests
    return (
      [
        ORDER_UI_STATUS.COMPLETED,
        ORDER_UI_STATUS.DELIVERED,
        ORDER_UI_STATUS.PARTIALLY_REFUNDED
      ] as OrderUIStatus[]
    ).includes(orderStatus)
  }

  // Fetch RefundReasonCode Custom Object from SFCC
  *getRefundReasonCodes() {
    try {
      if (this.refundReasonCodes.length === 0) {
        const res: Response = yield fetch(
          `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/SelfServeRefunds-GetReasonCodes`
        )
        const json: {reasonCodes?: string[] } = yield res.json()
        if (json?.reasonCodes && Array.isArray(json?.reasonCodes)) {
          this.refundReasonCodes = json.reasonCodes
        }
      }
    } catch (error) {
      this.status = 'error'
      throw error
    }
  }

  // Self Serve Refunds
  isOrderAvailableForSelfServeRefund(
    daysSinceDelivery: number | null,
    hoursSinceEndOfDelivery: number | null,
    orderState: OrderState | OrderStateForSelfServe
  ) { 
    const {isStoreSelfServeRefundEnabled, timeOfLastRefundRequest, hasReturnableItems, orderStatus} = orderState
    const response = {
      checkComplete: false,
      value: false
    }
    if (daysSinceDelivery == null ||
      hoursSinceEndOfDelivery == null ||
      !isStoreSelfServeRefundEnabled ||
      timeOfLastRefundRequest === undefined ||
      !orderStatus ||
      hasReturnableItems === undefined
    ) {
      return response
    }
    response.value = (
      this.orderStatusIsSuitableForSelfServe(orderStatus) &&
      this.isSelfServeRefundEnabled &&
      !this.exceededNumberOfDaysAfterDeliveryForRefund(daysSinceDelivery) &&
      this.metMinimumNumberOfHoursAfterDeliverySlotForRefund(hoursSinceEndOfDelivery) &&
      this.enoughTimeSinceLastRefundRequest(timeOfLastRefundRequest) &&
      hasReturnableItems
    )
    response.checkComplete = true
    return response
  }

  // Compare custom pref for hours between order delivery slot ended/delivered and refund request
  metMinimumNumberOfHoursAfterDeliverySlotForRefund(numberOfHours: number) {
    const minHoursCustomPref = this.minHoursAfterOrderPlacedforRefund
    if (!minHoursCustomPref) return false
    return numberOfHours >= minHoursCustomPref
  }

  // Save the form data to the store
  saveRefundForm(data: RefundFormData, orderId: string) {
    const formattedData: RefundFormData = formatSelfServeRefundFormData(data)
    // Save the formatted data to the store
    this.refundFormData = {...formattedData}
    this.currentOrderForSelfServe = orderId
  }


  // Submit the self serve request
  *submitRefund(orderNo: string, refundData: RefundData) {
    let successResponse = false;
    if (orderNo && refundData) {
      try {
        const res: Response = yield fetch(
          `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/SelfServeRefunds-Submit`,
          {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              orderid: orderNo,
              items: refundData?.refundItems?.map(item => ({
                lineItemId: item.itemId,
                reasonCode: item.reasonCode,
                returnQty: item.refundQuantity
              }))
            })
          }
        )
        const json: { success: boolean} = yield res.json()
        successResponse = json.success;
      } catch (error) {
        this.status = 'error'
        throw error
      }
    }
    const status = successResponse ? 'submitted' : 'something-went-wrong'
    return {redirect: `/account/order-history/${orderNo}/report-a-problem/${status}`}
  }

}
