import { getMobileAppOS } from "../../../utils/utils";

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export const setDefaultConsent = function (oneTrustEnabled: boolean) {
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line prefer-rest-params
  function gtag(...args: any[]){window.dataLayer.push(args);}
  const consentStatus = oneTrustEnabled ? "denied" : "granted"
  gtag('consent', 'default', {
      ad_storage: consentStatus,
      analytics_storage: consentStatus,
      functionality_storage: consentStatus,
      personalization_storage: consentStatus,
      security_storage: consentStatus,
      'wait_for_update': 500
  })
}

export const initOneTrust = (oneTrustEnabled: boolean, oneTrustId: string) => {
  if (location.pathname == '/login' && getMobileAppOS() || document.getElementById('OneTrustScript')) {
    // If loaded on the app and just the login page then don't show OT.
    // Or script already exists don't create again
    return;
  }
  if (oneTrustEnabled && oneTrustId) {
    // OneTrust Loader Script
    const script = document.createElement('script')
    script.id = 'OneTrustScript'
    script.type = 'text/javascript'
    script.src = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js'
    script.setAttribute('data-domain-script', oneTrustId)
    document.head.appendChild(script) 
    // OneTrust - Optanon Wrapper
    script.type = 'text/javascript'
    script.innerHTML = 'function OptanonWrapper() { }'
    document.head.appendChild(script)
  }
}