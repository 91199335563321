import {useBasketStore, useProductStore} from './useStore'

export const useBasketItem = (itemId: string) => {
  const {basket, allProductLineItems} = useBasketStore()
  const {productsById} = useProductStore()

  const item = allProductLineItems?.find((_item) => _item.productId === itemId)
  const product = item ? productsById[item.productId!] : null

  const quantity = item?.quantity || 0

  return {item, product, quantity}
}
