import { LocalStorage } from "./storages"

class CrossTabsEvents {
  private static instance: CrossTabsEvents
  private readonly eventsHandlers: Record<string, Function[]>

  private constructor() {
    this.eventsHandlers = {}
  }

  public static getInstance() {
    if (!CrossTabsEvents.instance) {
      CrossTabsEvents.instance = new CrossTabsEvents()
    }

    return CrossTabsEvents.instance
  }

  getEventHandlers(key: string) {
    return this.eventsHandlers[key] || []
  }

  setEventHandler(key: string, callback: Function) {
    this.eventsHandlers[key] = [...(this.eventsHandlers[key] || []), callback]
  }

  removeEventHandler(key: string, callback: Function) {
    const handlers = this.getEventHandlers(key)
    const index = handlers.indexOf(callback)

    if (index > -1) {
      this.eventsHandlers[key].splice(index, 1)
    }
  }
}

export const sendCrossTabEvent = (name: string, value: string) => {
  if (typeof window !== 'undefined') {
    try {
      LocalStorage.set(name, value)
    } catch (error) {
      console.error('Could not write to local storage:')
      console.error(error)
    }
  }
}

if (typeof window !== 'undefined') {
  window.addEventListener('storage', ({key, newValue}) => {
    const crossTabEvents = CrossTabsEvents.getInstance()

    if (!key) return

    crossTabEvents.getEventHandlers(key).forEach((callback) => {
      try {
        callback(newValue)
      } catch (error) {
        console.error(`Error calling callback for "${key}" event:`)
        console.error(error)
      }
    })
  })
}

export const listenToCrossTabEvent = (name: string, callback: Function) => {
  const crossTabEvents = CrossTabsEvents.getInstance()

  crossTabEvents.setEventHandler(name, callback)

  return () => {
    crossTabEvents.removeEventHandler(name, callback)
  }
}
