import {useGTMDispatch} from './react-gtm-hook/index'
import {transformAlgoliaItemsForGA4} from './utils'

export const useViewItemListTracking = () => {
  const sendDataToGTM = useGTMDispatch();
  const sendViewItemListData = (items, listType, pageId) => {
    setTimeout(() => {
      try {
        const _items = transformAlgoliaItemsForGA4(items, null, listType?.name ?? listType);
        let listId;
        if (pageId) {
          listId = pageId;
        } else {
          listId = listType?.id ?? 'Search Results'
        }
        sendDataToGTM({
          event: 'view_item_list',
          ecommerce: {
            item_list_id: listId,
            item_list_name: listType?.name ?? listType,
            items: _items,
            item_list_total: _items.length,
          }
        })
      } catch (error) {
        console.error('useViewItemListTracking error within setTimeout: ' + error.message);
      }
    }, 0);
  };
  return sendViewItemListData;
};