import React from 'react'
import { Button, Text, Stack } from '../../vanilla'
import { useOrderStore } from '../../store/hooks/useStore'
import { ORDER_UI_STATUS } from '../../utils/constants'
import { OrderUIStatus } from '../../types/store/order'
import { DateUtils } from '../../utils/date-utils'
import useNavigation from '../../hooks/use-navigation'
import { useTimer } from '../../hooks/use-timer'
import { ShopperCustomers } from 'commerce-api'

interface DeliveryOrderInfoProps {
    order: ShopperCustomers.Order
    isMiniBasket?: boolean
}

export const DeliveryOrderInfo = ({ order, isMiniBasket = false }: DeliveryOrderInfoProps) => {
    const {getOrderEditAvailabilityExpiryTimeISO, getOrderStatus} = useOrderStore()
    const deliveryStatus = getOrderStatus(order) as OrderUIStatus
    const navigate = useNavigation()
    const [deliveryTime, deliveryDate] =
    DateUtils.formatDeliveryDate(order.c_windowStartTime, order.c_windowEndTime, 'do MMMM') || []

    const editExpiryTime =
      deliveryStatus === ORDER_UI_STATUS.PLACED
        ? getOrderEditAvailabilityExpiryTimeISO(order) ?? undefined
        : undefined

        const sameDayDelivery =
        order.c_windowStartTime?.substring(0, 10) === order.creationDate?.substring(0, 10)
    const ExpiryCountDown = ({expiryTime}: {expiryTime: string}) => {
        const {minutes, hours} = useTimer(expiryTime || '', true)
      
        return (
            <Text as="span" variant="text4" color="accent0">
                {`${hours}h:${minutes}m`}
            </Text>
        )
    }
    return (
        <>
            <Text variant="text4" flexGrow="1">
                <Text variant="text4" color="accent0">
                    {isMiniBasket ? "Your order's scheduled for delivery:" : "We'll deliver your order between:"}
                </Text>
                {`${deliveryTime}, ${deliveryDate}`}
            </Text>
            {!sameDayDelivery && 
            <>
            <Text variant="text4">
                {!isMiniBasket && <>Have you got everything you need? <br/></>}
                You've got {editExpiryTime && <ExpiryCountDown expiryTime={editExpiryTime}/>} to update your order!
            </Text>
            <Button 
             variant={isMiniBasket ? "secondary" : "primary"}
             size="sm" width={isMiniBasket ? "full" : "150px"}
             onClick={() => navigate('/account/order-history')}>
                Check Order
            </Button>
 
            </>
            
}
        </>
    )
}