import '@algolia/autocomplete-theme-classic'
import React, {createElement, Fragment, useCallback, useEffect, useRef} from 'react'
import debounce from 'lodash.debounce'
import classNames from 'classnames'
import {observer} from 'mobx-react-lite'
import {render} from 'react-dom'
import {IntlProvider, useIntl} from 'react-intl'
import {autocomplete, getAlgoliaResults, Render} from '@algolia/autocomplete-js'
import {createRedirectUrlPlugin} from '@algolia/autocomplete-plugin-redirect-url'
import {useVirtualPageview} from '../../../analytics/page-tracking'
import useNavigation from '../../../hooks/use-navigation'
import {
  useBasketStore,
  useCustomerStore,
  useGlobalStore,
  useProductStore,
  useStore,
} from '../../../store/hooks/useStore'
import {Button, Flex} from '../../../vanilla'
import * as styles from './styles.css'
import {transformHit} from '../util/transformHit'
import {BrowserRouter, useHistory} from 'react-router-dom'
import {StoreProvider, CommerceAPIProvider} from '../../../contexts'
import {vars} from '../../../vanilla/vars.css'
import {DEFAULT_MIN_SEARCH_QUERY_LENGTH, DEFAULT_SEARCH_DELAY} from '../../../utils/constants'
import {AlgoliaAnalyticsContext, AlgoliaAnalyticsSetup} from '../../../analytics/algolia'
import useEinstein from '../../../store/hooks/useEinstein'

import {BookADeliveryPopoverContext} from '../../../contexts/book-delivery-popover-context'
import {CredentialModalContext} from '../../../contexts/credintial-modal-context'
import {GTMProvider} from '../../../analytics/react-gtm-hook'
import { Hit, TransformedHit } from '../../../store/ProductStore'
import { HitComponent } from './Hit'
import { ModalNavigationProps } from '../../../types/ui'
import { debouncePromise } from '../../../utils/utils'

export const Autocomplete = observer(() => {
  const {customSitePreferences, selectedStoreId, algoliaIndexBase, selectedAvailabilityDayOffset, searchClient} =
    useGlobalStore()
  const indexName = `${algoliaIndexBase}__products__default`
  const store = useStore()
  const basketStore = useBasketStore()
  const {isRegistered} = useCustomerStore()
  const {sendViewSearch} = useEinstein()
  const {formatNumber} = useIntl()
  const navigate = useNavigation()
  const {storeHitsAsProducts} = useProductStore()
  const containerRef = useRef(null)
  const searchRef = useRef<ReturnType<typeof autocomplete>>()
  const lastItemsRef = useRef([] as TransformedHit[])
  const lastQueryLengthRef = useRef(0)
  const sendVirtualPageview = useVirtualPageview()
  const history = useHistory()

  const modalNavigationProps: ModalNavigationProps = {
    searchRef,
    history
  }
  

  interface RedirectUrl {
    data?: {
      sourceId: string
      urls: string[]
    }[]
  }

  const debouncedSendViewSearchEvent = useCallback(
    debounce(
      (searchText: string, einsteinViewProducts: {id: string}[]) =>
        sendViewSearch({
          searchText: searchText,
          products: einsteinViewProducts,
        }),
      500,
    ),
    [],
  )

  const DEBOUNCE_MS = Number(customSitePreferences['searchDelayMilliseconds']) || DEFAULT_SEARCH_DELAY

  // Search delay Debounce wrapper
  const debounceSources = debouncePromise((items) => Promise.resolve(items), DEBOUNCE_MS)

  function transformResponse(response: any) {
    const redirectURL = response.renderingContent?.redirect?.url
    return redirectURL
  }

  // Add a template in here to style the redirect dropdown that appears
  // https://www.algolia.com/doc/ui-libraries/autocomplete/api-reference/autocomplete-plugin-redirect-url/createRedirectUrlPlugin/#param-templates
  const redirectUrlPlugin = createRedirectUrlPlugin({
    transformResponse,
  })

  let availabilityFilter = `matrixAvailability.${selectedAvailabilityDayOffset}:-${selectedStoreId}`
  const inStockFilter = `in_stock:true`
  
  const clearSearchField = () => {
      // Clear search input after search
      if (searchRef.current) {
        searchRef.current.setQuery('')
      }
  }
  const searchMinQueryLength = Number(customSitePreferences['searchMinQueryLength']) || DEFAULT_MIN_SEARCH_QUERY_LENGTH;

  useEffect(() => {
    // Prevents search loading before a store is set
    if (selectedAvailabilityDayOffset === null || selectedStoreId === null) {
      return undefined
     }

    availabilityFilter = `matrixAvailability.${selectedAvailabilityDayOffset}:-${selectedStoreId}`

    const search = autocomplete({
      container: containerRef.current,
      renderer: {createElement, Fragment, render: render as Render},
      openOnFocus: false,
      detachedMediaQuery: 'none',
      stallThreshold: 2000,
      classNames: {
        form: styles.form,
        inputWrapper: styles.inputWrapper,
        inputWrapperPrefix: styles.inputWrapperPrefix,
        inputWrapperSuffix: styles.inputWrapperSuffix,
        input: styles.input,
        loadingIndicator: styles.loadingIndicator,
        submitButton: styles.submitButton,
        item: styles.item,
        source: styles.source,
    },
      placeholder: 'Search Groceries...',
      plugins: [redirectUrlPlugin],
      getSources: async ({query}) => {
        const sources = await debounceSources([
          {
            sourceId: 'products',
            getItems({state}) {
              const isDeleting = state.query.length < lastQueryLengthRef.current
              lastQueryLengthRef.current = state.query.length
              if (!isDeleting && state.query.length < searchMinQueryLength) return []
              if (isDeleting && state.query.length < searchMinQueryLength)
                return lastItemsRef.current
    
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName,
                    query,
                    facetFilters: [availabilityFilter, inStockFilter],
                    params: {clickAnalytics: true},
      
                    },
                  ],
                  transformResponse({hits}) {
                    // Note the `hits` is an array of arrays, each containing a query
                    // result set. We only have a single query, so we only have a single
                    // array in the `hits` array.
                    const transformedHits: TransformedHit[] = hits[0]?.map((hit: Hit) => transformHit(hit, state))
                    storeHitsAsProducts(transformedHits)
  
                    /* Einstein View Search Event */
                    if (transformedHits?.length) {
                      debouncedSendViewSearchEvent(
                        query,
                        transformedHits.map((hit: Hit) => ({id: hit.id})),
                      )
                    }
  
                    lastItemsRef.current = transformedHits
                    return [transformedHits]
                  }
              })
            },
            templates: {
              footer({items, Fragment}) {
                if (items?.length === 0) {
                  return <Fragment />
                }
    
                return (
                  <Flex width="full" justify="center" paddingX="12px" paddingTop="12px">
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        query && history.push({pathname:`/search`, search:`?q=${query}`}) // Go to the Search Page
                        searchRef?.current?.setIsOpen(false) // Close the search dropdown
                        clearSearchField()
                      }}
                      style={{backgroundColor: vars.color.accent2, padding: '0px 16px'}}
                    >
                      View all &lsquo;{query}&rsquo; products
                    </Button>
                  </Flex>
                )
              },
              item({item, components, state}) {
                return (
                  <CommerceAPIProvider value={store.api}>
                    <StoreProvider value={store}>
                      <GTMProvider state={store.globalStore.gtmId}>
                        <IntlProvider locale="en-GB">
                          <AlgoliaAnalyticsContext>
                            <AlgoliaAnalyticsSetup />
                            <BrowserRouter>
                              <CredentialModalContext>
                                <BookADeliveryPopoverContext>
                                  <HitComponent
                                    hit={item as Hit}
                                    components={components}
                                    formatNumber={formatNumber}
                                    basketStore={basketStore}
                                    navigate={navigate}
                                    searchQuery={state.query}
                                    modalNavigationProps={modalNavigationProps}
                                  />
                                </BookADeliveryPopoverContext>
                              </CredentialModalContext>
                            </BrowserRouter>
                          </AlgoliaAnalyticsContext>
                        </IntlProvider>
                      </GTMProvider>
                    </StoreProvider>
                  </CommerceAPIProvider>
                )
              },
            },
          },
        ]);
        return sources;
      },
      onSubmit: (params) => {
        const query = params.state.query
      query && history.push({pathname:`/search`, search:`?q=${query}`}) // Go to the search page
      searchRef?.current?.setIsOpen(false) // Close the search dropdown
        clearSearchField()
  
        // Check for a search redirect and trigger it
        if (params.state.context.redirectUrlPlugin) {
          const redirectURL: RedirectUrl = params.state.context.redirectUrlPlugin
          if (redirectURL.data && redirectURL.data.length > 0 && 'urls' in redirectURL.data[0]) {
            const urls = redirectURL.data[0].urls
            sendVirtualPageview(urls[0], query)
          }
        }
      },
    })

    searchRef.current = search

    return () => {
      search.destroy()
    }  
  }, [selectedStoreId])

  return (
    <Flex
      ref={containerRef}
      className={classNames({'sm-autocomplete': isRegistered})}
      data-test-selector="search"
      width="full"
    />
  )
})